import { LogoutOptions } from '@auth0/auth0-react';
import { PortalAPI } from '@platform/api';
import { Constants, PlanNames } from '@platform/app-config';
import { CompanySurveyAnswersV1, RouteArgs, ScoutingFilter } from '@platform/helpers';
import { Smarty, StripeApi } from '@platform/integrations';
import { MailRemovedReasons } from '@platform/model/dist/types/prospect';
import { Portal } from '@platform/ui-helpers';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Polygon } from 'geojson';
import { batch } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import type { GetAccessTokenFunction } from '../../types/auth0';
import { MailDeliveryType, ProspectCountStepType } from '../../types/campaign';
import { NotificationType } from '../../types/notification';
import { shouldRecheckSubscription } from '../../utils/subscription-error';
import { Actions, AppThunk } from '../actions';
import { AddressSuggestion } from '../actions/address-lookup-actions';
import { AppActions } from '../actions/app-actions';
import { CampaignActions } from '../actions/campaign-actions';
import { ProspectsActions } from '../actions/prospect-actions';
import { TeamActions } from '../actions/team-actions';
import { CampaignCreatorSelectors, Selectors } from '../selectors';
import { LoadedCompany } from '../state/company-state';

/** USER **/
export const loadUserProfile: (getAccessToken: GetAccessTokenFunction) => AppThunk<Promise<void>> =
  (getAccessToken) => async (dispatch, getState) => {
    const existingUser = Selectors.user(getState());
    const existingCompany = Selectors.company(getState());
    if (existingUser.isLoaded && existingUser.user && existingCompany && existingCompany.isLoaded) {
      batch(() => {
        dispatch(Actions.User.loaded(existingUser.user!));
        dispatch(Actions.Company.loaded(existingCompany as LoadedCompany));
      });
    } else {
      batch(() => {
        dispatch(Actions.User.loading());
        dispatch(Actions.Company.loading());
      });

      const { status, data } = await PortalAPI.Client.getApiClient(getAccessToken).request<
        typeof PortalAPI.UserAPI.API['GET /user'],
        PortalAPI.UserAPI.QueryParams['GET /user']
      >({
        route: PortalAPI.UserAPI.API['GET /user'],
        queryParams: { platform: 'web' },
      });

      if (status === 200 && data) {
        const { company, ...user } = data;
        batch(() => {
          dispatch(Actions.User.loaded(user));
          dispatch(Actions.Company.loaded(company));
        });
      }
    }
  };

export const updateUserById: (
  getAccessToken: GetAccessTokenFunction,
  body: PortalAPI.UserAPI.Requests['PATCH /user']
) => AppThunk<Promise<void>> = (getAccessToken, body) => async (dispatch) => {
  const { status, data } = await PortalAPI.Client.getApiClient(getAccessToken).request(
    {
      route: 'PATCH /user',
    },
    body
  );

  if (status === 200 && data) {
    dispatch(Actions.User.update(data));
  }
};

export const getTeam: (getAccessToken: GetAccessTokenFunction) => AppThunk<Promise<void>> =
  (getAccessToken) => async (dispatch) => {
    dispatch(Actions.Team.loading());
    const { status, data } = await PortalAPI.Client.getApiClient(getAccessToken).request({
      route: 'GET /team',
    });

    if (status === 200 && data) {
      dispatch(Actions.Team.loaded(data));
    }
  };

export const setInitialPage: (navigate: NavigateFunction) => AppThunk<void> = (navigate) => (dispatch, getState) => {
  const company = getState().Company;
  if (!company.hasCampaigns && company.isLoaded && !getState().App.didRedirectToGettingStarted) {
    dispatch(AppActions.didRedirectToGettingStarted());
    navigate(Portal.getRouteName('/getting-started'));
  }
};

export const updateCampaignAssignment: (
  externalId: string,
  assignedToUser: { name: string; id: number },
  getAccessToken: GetAccessTokenFunction
) => AppThunk<Promise<void>> = (externalId, assignedToUser, getAccessToken) => async (dispatch, getState) => {
  let ret: AxiosResponse<PortalAPI.CampaignAPI.Responses['PATCH /campaigns/:id']>;

  const original = Selectors.campaignById(externalId)(getState());

  dispatch(
    Actions.Campaign.update({
      externalId,
      update: { assignedToUserName: assignedToUser.name, assignedToUserId: assignedToUser.id },
    })
  );

  try {
    ret = await PortalAPI.Client.getApiClient(getAccessToken).request(
      {
        route: 'PATCH /campaigns/:id',
        params: { id: `${externalId}` },
      },
      { assignedUserId: assignedToUser.id }
    );

    if (ret.data && typeof ret.data === 'object' && !('error' in ret.data)) {
      dispatch(
        AppActions.notification({
          type: NotificationType.success,
          title: 'Assignment Successful',
          description: `Campaign assigned to ${assignedToUser.name}`,
        })
      );
    } else {
      batch(() => {
        if (original) {
          dispatch(
            Actions.Campaign.update({
              externalId,
              update: { assignedToUserName: original.assignedToUserName, assignedToUserId: original.assignedToUserId },
            })
          );
        }
        dispatch(
          AppActions.notification({
            type: NotificationType.error,
            title: 'Unable to update assigned user',
            description: 'Please contact support@leadscoutapp.com for support',
          })
        );
      });
    }
  } catch (e: any) {
    const res = e.response as typeof ret;
    batch(() => {
      if (original) {
        dispatch(
          Actions.Campaign.update({
            externalId,
            update: { assignedToUserName: original.assignedToUserName, assignedToUserId: original.assignedToUserId },
          })
        );
      }
      dispatch(
        AppActions.notification({
          type: NotificationType.error,
          title: 'error' in res.data ? res.data.error : 'Unable to update assigned user',
          description: 'Please contact support@leadscoutapp.com for support',
        })
      );
    });
  }
};

export const updateCampaignTitle: (
  externalId: string,
  title: string,
  getAccessToken: GetAccessTokenFunction
) => AppThunk<Promise<void>> = (externalId, title, getAccessToken) => async (dispatch, getState) => {
  let ret: AxiosResponse<PortalAPI.CampaignAPI.Responses['PATCH /campaigns/:id']>;

  const original = Selectors.campaignById(externalId)(getState());

  dispatch(Actions.Campaign.update({ externalId, update: { title } }));

  try {
    ret = await PortalAPI.Client.getApiClient(getAccessToken).request(
      {
        route: 'PATCH /campaigns/:id',
        params: { id: externalId },
      },
      { campaignTitle: title }
    );

    if (!(ret.data && typeof ret.data === 'object' && !('error' in ret.data))) {
      batch(() => {
        if (original) {
          dispatch(Actions.Campaign.update({ externalId, update: { title: original?.title } }));
        }
        dispatch(
          AppActions.notification({
            type: NotificationType.error,
            title: 'Unable to update name',
            description: 'Please contact support@leadscoutapp.com for support',
          })
        );
      });
    }
  } catch (e: any) {
    const res = e.response as typeof ret;
    batch(() => {
      if (original) {
        dispatch(Actions.Campaign.update({ externalId, update: { title: original?.title } }));
      }
      dispatch(
        AppActions.notification({
          type: NotificationType.error,
          title: 'error' in res.data ? res.data.error : 'Unable to update name',
          description: 'Please contact support@leadscoutapp.com for support',
        })
      );
    });
  }
};

export const inviteUser: (
  getAccessToken: GetAccessTokenFunction,
  user: PortalAPI.UserAPI.Requests['POST /invite']
) => AppThunk<Promise<void>> = (getAccessToken, user) => async (dispatch) => {
  let ret: AxiosResponse<PortalAPI.UserAPI.Responses['POST /invite']>;

  try {
    ret = await PortalAPI.Client.getApiClient(getAccessToken).request(
      {
        route: 'POST /invite',
      },
      user
    );

    if (ret.data && typeof ret.data === 'object' && !('error' in ret.data)) {
      dispatch(Actions.Team.invite(ret.data));
      dispatch(
        AppActions.notification({
          type: NotificationType.success,
          title: `User Invited`,
          description: `${user.firstName} has been invited`,
        })
      );
    } else {
      dispatch(
        AppActions.notification({
          type: NotificationType.error,
          title: 'Unknown Error',
          description: 'Please contact support@leadscoutapp.com for support',
        })
      );
    }
  } catch (e: any) {
    const res = e.response as typeof ret;
    dispatch(
      AppActions.notification({
        type: NotificationType.error,
        title: 'error' in res.data ? res.data.error : 'Unknown Error',
        description: 'Please contact support@leadscoutapp.com for support',
      })
    );
  }
};

export const deleteUser: (getAccessToken: GetAccessTokenFunction, id: number) => AppThunk<Promise<void>> =
  (getAccessToken, id) => async (dispatch) => {
    try {
      await PortalAPI.Client.getApiClient(getAccessToken).request({
        route: 'DELETE /user/:id',
        params: { id: id.toString() },
      });
      dispatch(TeamActions.removeUser(id));
      dispatch(
        AppActions.notification({
          type: NotificationType.success,
          title: `User deleted`,
          description: `User has been deleted`,
        })
      );
    } catch (e: any) {
      const res = e.response as AxiosResponse<{ error: string }>;
      dispatch(
        AppActions.notification({
          type: NotificationType.error,
          title: `Failed to delete user`,
          description: 'error' in res.data ? res.data.error : 'Unknown Error',
        })
      );
    }
  };

export const deleteInvite: (getAccessToken: GetAccessTokenFunction, id: number) => AppThunk<Promise<void>> =
  (getAccessToken, id) => async (dispatch) => {
    try {
      await PortalAPI.Client.getApiClient(getAccessToken).request({
        route: 'DELETE /invite/:id',
        params: { id: id.toString() },
      });
      dispatch(TeamActions.removeInvite(id));
      dispatch(
        AppActions.notification({
          type: NotificationType.success,
          title: `Invite deleted`,
          description: `Invitation has been deleted`,
        })
      );
    } catch (e: any) {
      const res = e.response as AxiosResponse<{ error: string }>;
      dispatch(
        AppActions.notification({
          type: NotificationType.error,
          title: `Failed to delete invitation`,
          description: 'error' in res.data ? res.data.error : 'Unknown Error',
        })
      );
    }
  };

export const createScoutingReport: (
  getAccessToken: GetAccessTokenFunction,
  filters: ScoutingFilter | null
) => AppThunk<Promise<void>> = (getAccessToken, filters) => async (dispatch, getState) => {
  const user = Selectors.user(getState());
  try {
    await PortalAPI.Client.getApiClient(getAccessToken).request<
      typeof PortalAPI.ReportAPI.API['POST /v1/report/scouting'],
      PortalAPI.QueryParams['POST /v1/report/scouting']
    >(
      {
        route: PortalAPI.ReportAPI.API['POST /v1/report/scouting'],
        queryParams: {
          platform: 'web',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
      filters
    );
    dispatch(
      AppActions.notification({
        type: NotificationType.success,
        title: `Export in progress`,
        description: `Export will be sent to ${user.user?.email ?? 'your email'}`,
      })
    );
  } catch (e: any) {
    const res = e.response as AxiosResponse<{ error: string }>;
    dispatch(
      AppActions.notification({
        type: NotificationType.error,
        title: `Failed to initiate export. Please try again.`,
        description:
          res?.data && typeof res.data === 'object' && 'error' in res.data ? res.data.error : 'Unknown Error',
      })
    );
  }
};

export const transferOwnership: (
  getAccessToken: GetAccessTokenFunction,
  toId: number,
  fromId: number
) => AppThunk<Promise<void>> = (getAccessToken, toId, fromId) => async (dispatch) => {
  try {
    await PortalAPI.Client.getApiClient(getAccessToken).request({
      route: 'POST /user/:id/ownership',
      params: { id: toId.toString() },
    });
    dispatch(TeamActions.transferOwnership(toId, fromId));
    dispatch(
      AppActions.notification({
        type: NotificationType.success,
        title: `Ownership Transferred`,
        description: `Ownership has been transferred successfully`,
      })
    );
  } catch (e: any) {
    const res = e.response as AxiosResponse<{ error: string }>;
    dispatch(
      AppActions.notification({
        type: NotificationType.error,
        title: `Failed to transfer ownership`,
        description: 'error' in res.data ? res.data.error : 'Unknown Error',
      })
    );
  }
};

export const resendInvite: (
  getAccessToken: GetAccessTokenFunction,
  id: number,
  fromEmail?: string
) => AppThunk<Promise<void>> = (getAccessToken, id, fromEmail) => async (dispatch) => {
  try {
    await PortalAPI.Client.getApiClient(getAccessToken).request({
      route: 'POST /invite/:id/resend',
      params: { id: id.toString() },
    });
    dispatch(
      AppActions.notification({
        type: NotificationType.success,
        title: `Invite sent`,
        description: `Invitation has been resent. ${fromEmail ? `A copy was also sent to ${fromEmail}` : ''}`,
      })
    );
  } catch {
    dispatch(
      AppActions.notification({
        type: NotificationType.error,
        title: `Failed to send`,
        description: `Failed to resend invitation`,
      })
    );
  }
};

export const logout: (auth0LogoutFunction: (options?: LogoutOptions) => void) => AppThunk<Promise<void>> =
  (auth0LogoutFunction) => async (dispatch) => {
    await auth0LogoutFunction({ returnTo: window.location.origin });
    dispatch(Actions.User.logout());
  };

/** CAMPAIGNS **/

export const loadCampaigns: (offset: number, getAccessToken: GetAccessTokenFunction) => AppThunk<Promise<void>> =
  (offset, getAccessToken) => async (dispatch) => {
    dispatch(Actions.Campaign.loading());

    const {
      status,
      data: { count, data },
    } = await PortalAPI.Client.getApiClient(getAccessToken).request({
      route: 'GET /campaigns/list',
      queryParams: {
        offset: `${offset}`,
        limit: `${Constants.DEFAULT_PAGE_SIZE}`,
      },
    });

    if (status === 200 && data && !Number.isNaN(count)) {
      dispatch(Actions.Campaign.loaded(data, count, offset));
    }
  };

export const loadPrograms: (getAccessToken: GetAccessTokenFunction) => AppThunk<Promise<void>> =
  (getAccessToken) => async (dispatch, getState) => {
    const { isLoading, isLoaded, programs } = Selectors.programs(getState());
    if (isLoaded) {
      dispatch(Actions.Programs.loaded(programs));
    } else if (!isLoading) {
      dispatch(Actions.Programs.loading());

      const { status, data } = await PortalAPI.Client.getApiClient(getAccessToken).request({
        route: 'GET /programs',
      });

      if (status === 200 && data) {
        dispatch(Actions.Programs.loaded(data));
      }
    }
  };

export const loadLeadStatuses: (getAccessToken: GetAccessTokenFunction) => AppThunk<Promise<void>> =
  (getAccessToken) => async (dispatch, getState) => {
    const { isLoading, isLoaded, leadStatuses } = Selectors.leadStatuses(getState());
    if (isLoaded) {
      dispatch(Actions.LeadStatus.loaded(leadStatuses));
    } else if (!isLoading) {
      dispatch(Actions.LeadStatus.loading());

      const { status, data } = await PortalAPI.Client.getApiClient(getAccessToken).request({
        route: 'GET /v1/lead-status',
      });

      if (status === 200 && data) {
        dispatch(Actions.LeadStatus.loaded(data));
      }
    }
  };

export const loadCampaignsMap: (getAccessToken: GetAccessTokenFunction) => AppThunk<Promise<void>> =
  (getAccessToken) => async (dispatch) => {
    dispatch(Actions.CampaignMap.loading());

    try {
      const { status, data } = await PortalAPI.Client.getApiClient(getAccessToken).request({
        route: 'GET /campaigns/map',
      });

      if (status === 200 && data) {
        dispatch(Actions.CampaignMap.loaded(data));
      } else {
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const error = (e ?? {}) as AxiosError<{ error?: string }>;
        if (error.response && error.response.data.error) {
          dispatch(
            Actions.App.notification({
              type: NotificationType.error,
              title: 'Error',
              description: error.response.data.error,
            })
          );
        }
      }
    }
  };

export const searchAddress: (search: string) => AppThunk<Promise<void>> = (search) => async (dispatch, getState) => {
  const existingSearch = Selectors.addressSuggestionsForSearch(search)(getState());
  if (existingSearch?.length) {
    dispatch(Actions.AddressLookup.searchCompleted(existingSearch));
    return;
  }

  dispatch(Actions.AddressLookup.searchStarted(search));
  const res = await new Smarty.SmartyAutocompleteClient(process.env.REACT_APP_SMARTY_EMBEDDED_KEY!).lookup({
    search,
  });
  if (res.success) {
    dispatch(Actions.AddressLookup.searchCompleted(res.data.suggestions));
  }
};

export const lookupStreetAddress: (
  suggestion: AddressSuggestion,
  getAccessToken: GetAccessTokenFunction
) => AppThunk<Promise<void>> = (suggestion, getAccessToken) => async (dispatch) => {
  dispatch(Actions.AddressLookup.lookupStarted());

  try {
    const { status, data } = await PortalAPI.Client.getApiClient(getAccessToken).request(
      { route: 'POST /v1/address/lookup' },
      suggestion
    );

    if (status === 200 && data && !('error' in data)) {
      dispatch(Actions.AddressLookup.lookupCompleted(data));
    } else if ('error' in data) {
      dispatch(
        Actions.App.notification({
          type: NotificationType.error,
          title: 'Error',
          description: data.error,
        })
      );
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = (e ?? {}) as AxiosError<{ error?: string }>;
      if (error.response && error.response.data.error) {
        dispatch(
          Actions.App.notification({
            type: NotificationType.error,
            title: 'Error',
            description: error.response.data.error,
          })
        );
      }
    }
  }
};

export const loadCampaignDetails: (
  campaignId: string,
  getAccessToken: GetAccessTokenFunction,
  onErrorCallback?: () => void
) => AppThunk<Promise<PortalAPI.CampaignAPI.Responses['GET /v2/campaigns/:id'] | undefined>> =
  (campaignId, getAccessToken, onErrorCallback) => async (dispatch) => {
    try {
      const { status, data } = await PortalAPI.Client.getApiClient(getAccessToken).request({
        route: 'GET /v2/campaigns/:id',
        params: { id: campaignId },
      });

      if (status === 200 && data) {
        dispatch(Actions.Campaign.set(data));
        return data;
      }
    } catch {
      if (typeof onErrorCallback === 'function') {
        onErrorCallback();
      }
    }
  };

export const getProspect: (prospectId: string, getAccessToken: GetAccessTokenFunction) => AppThunk<Promise<void>> =
  (prospectId, getAccessToken) => async (dispatch, getState) => {
    if (!Selectors.prospectById(prospectId)(getState())?.isLoading) {
      dispatch(Actions.Prospects.prospectLoading({ externalId: prospectId }));
      const { status, data } = await PortalAPI.Client.getApiClient(getAccessToken).request<
        typeof PortalAPI.ProspectAPI.API['GET /v3/prospects/:externalId'],
        PortalAPI.ProspectAPI.QueryParams['GET /v3/prospects/:externalId']
      >({
        route: PortalAPI.ProspectAPI.API['GET /v3/prospects/:externalId'],
        params: { externalId: prospectId },
        queryParams: { platform: 'web' },
      });

      dispatch(Actions.Prospects.prospectLoaded({ id: prospectId, data: status === 200 && !!data ? data : null }));
    }
  };

export const updateProspectAddress: (
  getAccessToken: GetAccessTokenFunction,
  params: PortalAPI.ProspectAPI.Requests['PATCH /v1/prospects/:externalId/address'] &
    RouteArgs<typeof PortalAPI.ProspectAPI.API['PATCH /v1/prospects/:externalId/address']>
) => AppThunk<Promise<void>> =
  (getAccessToken, { externalId, ...contact }) =>
  async (dispatch) => {
    let ret: AxiosResponse<PortalAPI.ProspectAPI.Responses['PATCH /v1/prospects/:externalId/address']>;

    try {
      ret = await PortalAPI.Client.getApiClient(getAccessToken).request<
        typeof PortalAPI.ProspectAPI.API['PATCH /v1/prospects/:externalId/address'],
        PortalAPI.ProspectAPI.QueryParams['PATCH /v1/prospects/:externalId/address']
      >(
        {
          route: PortalAPI.ProspectAPI.API['PATCH /v1/prospects/:externalId/address'],
          params: {
            externalId,
          },
          queryParams: {
            platform: 'web',
          },
        },
        {
          ...contact,
        }
      );

      if (ret.data && !('error' in ret.data)) {
        dispatch(ProspectsActions.setProspectAddress(ret.data));
      }
    } catch (e: any) {
      dispatch(
        AppActions.notification({
          type: NotificationType.error,
          title: 'Error updating address',
          description: 'Please contact support@leadscoutapp.com for support',
        })
      );
    }
  };

export const loadCampaignProspects: (
  campaignId: string,
  getAccessToken: GetAccessTokenFunction
) => AppThunk<Promise<void>> = (campaignId, getAccessToken) => async (dispatch) => {
  // TODO: I think this will be going away entirely
  const { status, data } = await PortalAPI.Client.getApiClient(getAccessToken).request<
    any,
    PortalAPI.ProspectAPI.QueryParams['GET /v3/prospects']
  >({
    route: PortalAPI.ProspectAPI.API['GET /v3/prospects'],
    queryParams: { campaignId: `${campaignId}` as `${number}`, sort: 'address', platform: 'web' },
  });

  if (status === 200 && data) {
    dispatch(Actions.Prospects.campaignLoaded(campaignId, data, 1, data.length));
  }
};

export const getCampaignProspectCount: (
  getAccessToken: GetAccessTokenFunction,
  polygon: Polygon
) => AppThunk<Promise<void>> = (getAccessToken, polygon) => async (dispatch, getState) => {
  try {
    const { address } = Selectors.addressLookup(getState());
    dispatch(Actions.CampaignCreator.setProspectCountStep(ProspectCountStepType.CALCULATING));

    const { status, data } = await PortalAPI.Client.getApiClient(getAccessToken).request(
      { route: 'POST /campaigns/prospect-count' },
      { polygon, ...(address && 'location' in address ? { referencePoint: address.location } : {}) }
    );

    if (status === 200 && data) {
      batch(() => {
        dispatch(Actions.CampaignCreator.setProspectTotalCount(data.totalCount));
        dispatch(Actions.CampaignCreator.setProspectCountStep(ProspectCountStepType.SELECT));
      });
    }
  } catch (e: any) {
    dispatch(Actions.CampaignCreator.setProspectCountStep(ProspectCountStepType.SELECT));
    if (axios.isAxiosError(e)) {
      const error = (e ?? {}) as AxiosError<{ error?: string }>;
      if (error.response && error.response.data.error) {
        dispatch(
          Actions.App.notification({
            type: NotificationType.error,
            title: 'Error',
            description: error.response.data.error,
          })
        );
      }
    }
  }
};

export const createNewCampaign: (
  getAccessToken: GetAccessTokenFunction,
  navigate: NavigateFunction
) => AppThunk<Promise<void>> = (getAccessToken, navigate) => async (dispatch, getState) => {
  try {
    const { address } = Selectors.addressLookup(getState());

    if (!address) {
      dispatch(
        Actions.App.notification({
          type: NotificationType.error,
          title: 'Error',
          description: 'No address found',
        })
      );
      return;
    }

    if ('error' in address) {
      dispatch(
        Actions.App.notification({
          type: NotificationType.error,
          title: 'Error',
          description: address.error,
        })
      );
      return;
    }

    const allowCreation = Selectors.getEnableCampaignCreation(getState());

    if (allowCreation) {
      const {
        programId,
        campaignTitle,
        programStartDate,
        mailDeliveryType,
        assignedToUserId,
        numberOfNeighbors,
        polygon,
        isMailProgramEnabled,
      } = CampaignCreatorSelectors.getForm(getState());

      dispatch(Actions.CampaignCreator.setIsSubmitting(true));

      const payload: PortalAPI.CampaignAPI.CampaignCreateRequest = {
        address,
        assignedToUserId,
        numberOfNeighbors,
        polygon: polygon!,
        autoActivateProgram: isMailProgramEnabled === true && mailDeliveryType === MailDeliveryType.SCHEDULE,
        campaignTitle,
        ...(isMailProgramEnabled
          ? {
              programId,
              programStartDate,
            }
          : {}),
      };

      const { data } = await PortalAPI.Client.getApiClient(getAccessToken).request(
        {
          route: 'POST /campaigns',
        },
        payload
      );

      if (!('error' in data)) {
        batch(() => {
          dispatch(Actions.CampaignCreator.setClearForm());
          dispatch(Actions.Campaign.add(data));
          dispatch(Actions.Campaign.watch(data.externalTrackingId));
          dispatch(loadCampaignsMap(getAccessToken));
        });
        navigate(Portal.getRouteName('/campaigns'));
      }
    } else {
      dispatch(
        Actions.App.notification({
          type: NotificationType.error,
          title: 'Error',
          description: 'Missing fields on the form',
        })
      );
    }
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const error = (e ?? {}) as AxiosError<{ error?: string }>;
      if (error.response && error.response.data.error) {
        dispatch(
          Actions.App.notification({
            type: NotificationType.error,
            title: 'Error',
            description: error.response.data.error,
          })
        );
      }
    }
  } finally {
    dispatch(Actions.CampaignCreator.setIsSubmitting(false));
  }
};

export const deleteProspect: (
  getAccessToken: GetAccessTokenFunction,
  prospectId: string,
  campaignId: string
) => AppThunk<Promise<void>> = (getAccessToken, prospectId, campaignId) => {
  return async (dispatch, getState) => {
    const prospect = Selectors.prospectById(prospectId)(getState());

    try {
      await PortalAPI.Client.getApiClient(getAccessToken).request<
        typeof PortalAPI.ProspectAPI.API['DELETE /v3/prospects/:externalId'],
        PortalAPI.ProspectAPI.QueryParams['DELETE /v3/prospects/:externalId']
      >({
        route: PortalAPI.ProspectAPI.API['DELETE /v3/prospects/:externalId'],
        params: { externalId: prospectId },
        queryParams: {
          platform: 'web',
        },
      });

      batch(() => {
        dispatch(ProspectsActions.removeProspect({ prospectId, campaignId }));
        if (prospect) {
          dispatch(
            CampaignActions.removeProspect({
              campaignId,
              prospectId,
              leadStatusId: prospect.leadStatus?.id ?? null,
            })
          );
        }
      });
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const error = (e ?? {}) as AxiosError<{ error?: string }>;
        if (error.response && error.response.data.error) {
          dispatch(
            Actions.App.notification({
              type: NotificationType.error,
              title: 'Error',
              description: error.response.data.error,
            })
          );
        }
      } else {
        dispatch(
          Actions.App.notification({
            type: NotificationType.error,
            title: 'Error',
            description: 'Unable to delete prospect',
          })
        );
      }
    }
  };
};

export const updateProspectNotes: (
  getAccessToken: GetAccessTokenFunction,
  notes: string,
  prospectId: string
) => AppThunk<Promise<{ error?: string }>> = (getAccessToken, notes, prospectId) => async (dispatch) => {
  try {
    const data: PortalAPI.ProspectAPI.Requests['POST /v2/prospects/:externalId/notes'] = {
      notes,
    };

    const { status, data: response } = await PortalAPI.Client.getApiClient(getAccessToken).request<
      typeof PortalAPI.ProspectAPI.API['POST /v2/prospects/:externalId/notes'],
      PortalAPI.ProspectAPI.QueryParams['POST /v2/prospects/:externalId/notes']
    >(
      {
        route: PortalAPI.ProspectAPI.API['POST /v2/prospects/:externalId/notes'],
        params: { externalId: prospectId },
        queryParams: {
          platform: 'web',
        },
      },
      data
    );

    if (status === 200 && !('error' in response)) {
      dispatch(
        ProspectsActions.setProspectNotes({
          notes: response.notes,
          notesUpdatedAt: response.updatedAt,
          notesUpdatedByUserName: response.updatedByUserName,
          prospectId,
        })
      );
      return {};
    } else {
      return { error: 'error' in response ? response.error : 'Unable to save note' };
    }
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const error = (e ?? {}) as AxiosError<{ error?: string }>;
      if (error.response && error.response.data.error) {
        return { error: error.response.data.error };
      }
    }
    dispatch(
      Actions.App.notification({
        type: NotificationType.error,
        title: 'Error',
        description: 'Unable to save note',
      })
    );
    return { error: 'Unable to save note' };
  }
};

export const updateProspectLeadStatus: (
  getAccessToken: GetAccessTokenFunction,
  leadStatusId: number,
  prospectId: string,
  campaignId: string,
  userId?: number
) => AppThunk<Promise<void>> =
  (getAccessToken, leadStatusId, prospectId, campaignId, userId) => async (dispatch, getState) => {
    const prospect = Selectors.prospectById(prospectId)(getState());
    const previousLeadStatus = prospect?.leadStatus?.id
      ? Selectors.leadStatusWithId(prospect.leadStatus.id)(getState())
      : null;
    const leadStatus = Selectors.leadStatusWithId(leadStatusId)(getState());

    dispatch(ProspectsActions.setProspectLeadStatus({ leadStatus, userId, prospectId }));

    try {
      const data: PortalAPI.ProspectAPI.Requests['POST /v1/prospects/:externalId/lead-status'] = {
        leadStatusId,
        location: null,
      };

      const { status } = await PortalAPI.Client.getApiClient(getAccessToken).request<
        typeof PortalAPI.ProspectAPI.API['POST /v1/prospects/:externalId/lead-status'],
        PortalAPI.ProspectAPI.QueryParams['POST /v1/prospects/:externalId/lead-status']
      >(
        {
          route: PortalAPI.ProspectAPI.API['POST /v1/prospects/:externalId/lead-status'],
          params: { externalId: prospectId },
          queryParams: {
            platform: 'web',
          },
        },
        data
      );

      if (status === 200) {
        dispatch(
          CampaignActions.updateCampaignLeadStatus({
            campaignId,
            prospectId,
            previousLeadStatus: previousLeadStatus?.id ?? null,
            newLeadStatus: {
              id: leadStatusId,
              value: leadStatus.value,
            },
          })
        );
      }
    } catch (e: any) {
      // rollback the optimistic update
      dispatch(
        ProspectsActions.setProspectLeadStatus({
          leadStatus,
          prospectId,
          userId,
        })
      );
      if (axios.isAxiosError(e)) {
        const error = (e ?? {}) as AxiosError<{ error?: string }>;
        if (error.response && error.response.data.error) {
          dispatch(
            Actions.App.notification({
              type: NotificationType.error,
              title: 'Error',
              description: error.response.data.error,
            })
          );
        }
      }
    }
  };

export const updateMailStatus: (
  getAccessToken: GetAccessTokenFunction,
  status: 'start' | 'stop',
  prospectId: string
) => AppThunk<Promise<void>> = (getAccessToken, status, prospectId) => async (dispatch) => {
  try {
    const { status: httpStatus } = await PortalAPI.Client.getApiClient(getAccessToken).request<
      typeof PortalAPI.ProspectAPI.API['PATCH /v2/prospects/:externalId/mail'],
      PortalAPI.ProspectAPI.QueryParams['PATCH /v2/prospects/:externalId/mail']
    >(
      {
        route: PortalAPI.ProspectAPI.API['PATCH /v2/prospects/:externalId/mail'],
        params: { externalId: prospectId },
        queryParams: {
          platform: 'web',
        },
      },
      {
        status,
        removedReason: status === 'stop' ? MailRemovedReasons.DISQUALIFIED : undefined,
      }
    );

    if (httpStatus === 200) {
      dispatch(ProspectsActions.setProspectMailStatus({ status, prospectId }));
    }
  } catch (e) {}
};

export const deleteProspectTag: (
  getAccessToken: GetAccessTokenFunction,
  params: RouteArgs<typeof PortalAPI.ProspectAPI.API['DELETE /v3/prospects/:externalId/tags/:tagExternalId']>
) => AppThunk<Promise<void>> =
  (getAccessToken, { externalId, tagExternalId }) =>
  async (dispatch, getState) => {
    const existingTags = Selectors.prospectById(externalId)(getState())?.prospectTags ?? [];
    try {
      dispatch(ProspectsActions.removeProspectTag({ prospectId: externalId, tagId: tagExternalId }));
      await PortalAPI.Client.getApiClient(getAccessToken).request<
        typeof PortalAPI.ProspectAPI.API['DELETE /v3/prospects/:externalId/tags/:tagExternalId'],
        PortalAPI.ProspectAPI.QueryParams['DELETE /v3/prospects/:externalId/tags/:tagExternalId']
      >({
        route: PortalAPI.ProspectAPI.API['DELETE /v3/prospects/:externalId/tags/:tagExternalId'],
        params: {
          externalId,
          tagExternalId,
        },
        queryParams: {
          platform: 'web',
        },
      });
    } catch (e: any) {
      // Undo the cache update
      dispatch(ProspectsActions.setProspectTags({ prospectId: externalId, tags: existingTags }));
      dispatch(
        AppActions.notification({
          type: NotificationType.error,
          title: 'Unable to remove tag',
          description: 'Please contact support@leadscoutapp.com for support',
        })
      );
    }
  };

export const updateProspectTags: (
  getAccessToken: GetAccessTokenFunction,
  params: RouteArgs<typeof PortalAPI.ProspectAPI.API['POST /v3/prospects/:externalId/tags']> & {
    tags: { externalId: string; value: string }[];
  }
) => AppThunk<Promise<void>> =
  (getAccessToken, { externalId, tags }) =>
  async (dispatch, getState) => {
    let ret: AxiosResponse<PortalAPI.ProspectAPI.Responses['POST /v3/prospects/:externalId/tags']>;

    const existingTags = Selectors.prospectById(externalId)(getState())?.prospectTags ?? [];
    dispatch(ProspectsActions.setProspectTags({ tags, prospectId: externalId }));
    try {
      ret = await PortalAPI.Client.getApiClient(getAccessToken).request<
        typeof PortalAPI.ProspectAPI.API['POST /v3/prospects/:externalId/tags'],
        PortalAPI.ProspectAPI.QueryParams['POST /v3/prospects/:externalId/tags']
      >(
        {
          route: PortalAPI.ProspectAPI.API['POST /v3/prospects/:externalId/tags'],
          params: {
            externalId,
          },
          queryParams: {
            platform: 'web',
          },
        },
        {
          tags,
        }
      );

      if (ret.status === 200 && ret.data && !('error' in ret.data)) {
        const tags = ret.data;
        dispatch(ProspectsActions.setProspectTags({ tags, prospectId: externalId }));
      }
    } catch (e: any) {
      const res = e.response as typeof ret;
      // Undo the cache update
      dispatch(ProspectsActions.setProspectTags({ prospectId: externalId, tags: existingTags }));
      dispatch(
        AppActions.notification({
          type: NotificationType.error,
          title: 'error' in res.data ? res.data.error : 'Unknown Error',
          description: 'Please contact support@leadscoutapp.com for support',
        })
      );
    }
  };

export const updateProspectContact: (
  getAccessToken: GetAccessTokenFunction,
  params: PortalAPI.ProspectAPI.Requests['PATCH /v2/prospects/:externalId/contact'] &
    RouteArgs<typeof PortalAPI.ProspectAPI.API['PATCH /v2/prospects/:externalId/contact']>
) => AppThunk<Promise<void>> =
  (getAccessToken, { externalId, ...contact }) =>
  async (dispatch) => {
    let ret: AxiosResponse<PortalAPI.ProspectAPI.Responses['PATCH /v2/prospects/:externalId/contact']>;

    try {
      ret = await PortalAPI.Client.getApiClient(getAccessToken).request<
        typeof PortalAPI.ProspectAPI.API['PATCH /v2/prospects/:externalId/contact'],
        PortalAPI.ProspectAPI.QueryParams['PATCH /v2/prospects/:externalId/contact']
      >(
        {
          route: PortalAPI.ProspectAPI.API['PATCH /v2/prospects/:externalId/contact'],
          params: {
            externalId,
          },
          queryParams: {
            platform: 'web',
          },
        },
        {
          ...contact,
        }
      );

      if (ret.status === 200 && ret.data && !('error' in ret.data)) {
        dispatch(ProspectsActions.setProspectContact(ret.data));
      }
    } catch (e: any) {
      dispatch(
        AppActions.notification({
          type: NotificationType.error,
          title: 'Error updating contact',
          description: 'Please contact support@leadscoutapp.com for support',
        })
      );
    }
  };

export const updateProspectScoutedBy: (
  getAccessToken: GetAccessTokenFunction,
  params: PortalAPI.ProspectAPI.Requests['POST /v2/prospects/:externalId/assignment'] &
    RouteArgs<typeof PortalAPI.ProspectAPI.API['POST /v2/prospects/:externalId/assignment']>
) => AppThunk<Promise<void>> =
  (getAccessToken, { externalId, assignedToId }) =>
  async (dispatch) => {
    let ret: AxiosResponse<PortalAPI.ProspectAPI.Responses['POST /v2/prospects/:externalId/assignment']>;

    try {
      ret = await PortalAPI.Client.getApiClient(getAccessToken).request<
        typeof PortalAPI.ProspectAPI.API['POST /v2/prospects/:externalId/assignment'],
        PortalAPI.ProspectAPI.QueryParams['POST /v2/prospects/:externalId/assignment']
      >(
        {
          route: PortalAPI.ProspectAPI.API['POST /v2/prospects/:externalId/assignment'],
          params: {
            externalId,
          },
          queryParams: {
            platform: 'web',
          },
        },
        {
          assignedToId,
        }
      );

      if (ret.status === 200) {
        dispatch(
          ProspectsActions.setProspectAssignedTo({
            assignedTo: ret.data,
            prospectId: externalId,
          })
        );
      }
    } catch (e: any) {
      dispatch(
        AppActions.notification({
          type: NotificationType.error,
          title: 'Error assigning prospect',
          description: 'Please contact support@leadscoutapp.com for support',
        })
      );
    }
  };

export const submitSurvey: (
  getAccessToken: GetAccessTokenFunction,
  survey: Partial<CompanySurveyAnswersV1>
) => AppThunk<Promise<void>> = (getAccessToken, survey) => async () => {
  try {
    await PortalAPI.Client.getApiClient(getAccessToken).request<
      typeof PortalAPI.SurveyAPI.API['POST /v1/survey'],
      PortalAPI.SurveyAPI.QueryParams['POST /v1/survey']
    >(
      {
        route: PortalAPI.SurveyAPI.API['POST /v1/survey'],
        queryParams: {
          platform: 'web',
        },
      },
      { ...survey, updatedAt: new Date() }
    );
  } catch (e) {
    // We don't need to inform the user if this fails
    console.error(`Failed to submit survey`);
    console.error(e);
  }
};

export const loginCheck: (
  getAccessToken: GetAccessTokenFunction,
  navigateTo: NavigateFunction,
  shouldCheckSubscriptionForPath: boolean
) => AppThunk<Promise<void>> =
  (getAccessToken, navigateTo, shouldCheckSubscriptionForPath) => async (dispatch, getState) => {
    const state = getState();
    if (
      shouldCheckSubscriptionForPath &&
      !Selectors.subscriptionStatusIsLoading(state) &&
      shouldRecheckSubscription(Selectors.subscriptionLastChecked(state))
    ) {
      dispatch(AppActions.setSubscriptionStatusLoading());

      try {
        const { data } = await PortalAPI.Client.getApiClient(getAccessToken).request({
          route: 'POST /portal/login-check',
        });

        const subscriptionActive = StripeApi.subscriptionIsActiveOrPaymentIssues(data.subscriptionStatus);

        dispatch(
          AppActions.setSubscriptionStatus({
            status: data.subscriptionStatus,
            lastChecked: subscriptionActive ? new Date() : null,
          })
        );

        if (data.redirect) {
          if (!data.redirect.isExternal) {
            navigateTo(data.redirect.url, { replace: true, state: data.redirect.state });
          } else {
            window.location.href = data.redirect.url;
          }
        } else {
          if (!subscriptionActive) {
            dispatch(
              AppActions.setSubscriptionErrorModal({
                subscriptionStatus: data.subscriptionStatus,
              })
            );
          }
        }
      } catch {
        console.error('Error getting user subscription status');
      }
    }
  };

export const stripeCheckout: (
  getAccessToken: GetAccessTokenFunction,
  params: { associationId?: string; product: PlanNames; freeTrial?: boolean }
) => AppThunk<Promise<void>> = (getAccessToken, params) => async (dispatch) => {
  const queryParams: PortalAPI.AuthAPI.QueryParams['GET /stripe-checkout'] = {
    ...(params.associationId ? { associationId: params.associationId } : {}),
    product: params.product,
    interval: 'monthly',
    ...(params.freeTrial === false ? { freeTrial: 'false' } : {}),
  };
  try {
    const { status, data } = await PortalAPI.Client.getApiClient(getAccessToken).request({
      route: 'GET /stripe-checkout',
      queryParams,
    });

    if (status === 200 && !data.error && !!data.redirect_uri) {
      window.location.href = data.redirect_uri;
    }
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 403) {
      const data = e.response?.data as PortalAPI.AuthAPI.Responses['GET /stripe-checkout'] | undefined;
      dispatch(
        AppActions.notification({
          type: NotificationType.error,
          title: 'Could not activate subscription',
          description:
            data && 'error' in data && data.error ? data.error : 'Please contact support@leadscoutapp.com for help',
        })
      );
    }
  }
};

export const createProspectAppointment: (
  getAccessToken: GetAccessTokenFunction,
  params: RouteArgs<typeof PortalAPI.ProspectAPI.API['POST /v1/prospects/:externalId/appointment']> & {
    appointmentTime: Date;
  }
) => AppThunk<Promise<void>> =
  (getAccessToken, { externalId, appointmentTime }) =>
  async (dispatch, getState) => {
    let ret: AxiosResponse<PortalAPI.ProspectAPI.Responses['POST /v1/prospects/:externalId/appointment']>;

    const previousAppointment = Selectors.prospectAppointmentTime(externalId)(getState());
    dispatch(ProspectsActions.setProspectAppointment({ appointmentTime, prospectId: externalId }));

    try {
      ret = await PortalAPI.Client.getApiClient(getAccessToken).request<
        typeof PortalAPI.ProspectAPI.API['POST /v1/prospects/:externalId/appointment'],
        PortalAPI.ProspectAPI.QueryParams['POST /v1/prospects/:externalId/appointment']
      >(
        {
          route: PortalAPI.ProspectAPI.API['POST /v1/prospects/:externalId/appointment'],
          params: {
            externalId,
          },
          queryParams: {
            platform: 'web',
          },
        },
        {
          appointmentTime,
        }
      );

      dispatch(
        AppActions.notification({
          type: NotificationType.success,
          title: 'Appointment set successfully',
          placement: 'lower-right',
        })
      );
    } catch (e: any) {
      const res = e.response as typeof ret;

      batch(() => {
        dispatch(
          previousAppointment
            ? ProspectsActions.setProspectAppointment({ prospectId: externalId, appointmentTime: previousAppointment })
            : ProspectsActions.removeProspectAppointment({ prospectId: externalId })
        );
        dispatch(
          AppActions.notification({
            type: NotificationType.error,
            title: 'Failed to set appointment',
            description: res?.data && 'error' in res.data ? res.data.error : 'Unknown Error',
          })
        );
      });
    }
  };

export const deleteProspectAppointment: (
  getAccessToken: GetAccessTokenFunction,
  params: RouteArgs<typeof PortalAPI.ProspectAPI.API['DELETE /v1/prospects/:externalId/appointment']>
) => AppThunk<Promise<void>> =
  (getAccessToken, { externalId }) =>
  async (dispatch, getState) => {
    const previousAppointment = Selectors.prospectAppointmentTime(externalId)(getState());
    dispatch(ProspectsActions.removeProspectAppointment({ prospectId: externalId }));
    try {
      await PortalAPI.Client.getApiClient(getAccessToken).request<
        typeof PortalAPI.ProspectAPI.API['DELETE /v1/prospects/:externalId/appointment'],
        PortalAPI.ProspectAPI.QueryParams['DELETE /v1/prospects/:externalId/appointment']
      >({
        route: PortalAPI.ProspectAPI.API['DELETE /v1/prospects/:externalId/appointment'],
        params: {
          externalId,
        },
        queryParams: {
          platform: 'web',
        },
      });

      dispatch(
        AppActions.notification({
          type: NotificationType.success,
          title: 'Successfully deleted appointment',
          placement: 'lower-right',
        })
      );
    } catch (e: any) {
      batch(() => {
        dispatch(
          ProspectsActions.setProspectAppointment({ appointmentTime: previousAppointment, prospectId: externalId })
        );
        dispatch(
          AppActions.notification({
            type: NotificationType.error,
            title: 'Unable to delete appointment',
            description: 'Please contact support@leadscoutapp.com for support',
          })
        );
      });
    }
  };
