import { PortalAPI } from '@platform/api';
import { HomeownerDataSubscription } from '@platform/model/dist/types/company';

export type LoadedCompany = PortalAPI.UserAPI.GetUserResponse['company'];

export type CompanyState = { [P in keyof LoadedCompany]: LoadedCompany[P] | undefined } & {
  isLoading: boolean;
  isLoaded: boolean;
} & {
  parent?: PortalAPI.CompanyAPI.ParentCompanyAssociation;
  children?: PortalAPI.CompanyAPI.ChildCompanyAssociation[];
  all: {
    list: PortalAPI.CompanyAPI.GetCompanyAllResponse;
    loaded: boolean;
    loading: boolean;
  };
};

export const initialCompanyState: CompanyState = {
  isLoaded: false,
  isLoading: false,
  billingEmail: undefined,
  homeownerDataSubscription: HomeownerDataSubscription.NONE,
  hasCampaigns: false,
  createdAt: undefined,
  externalId: undefined,
  name: undefined,
  stripeSubscriptionId: undefined,
  stripeCustomerId: undefined,
  formSubmissionEmail: undefined,
  phoneNumber: undefined,
  website: undefined,
  users: 0,
  hasPrograms: false,
  all: {
    list: [],
    loaded: false,
    loading: false,
  },
};
