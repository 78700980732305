import { PortalAPI } from '@platform/api';

export interface CampaignProspects {
  totalRecords: number;
  ids: string[];
}

export interface ProspectsState {
  prospects: {
    [id: string]: PortalAPI.ProspectAPI.ProspectResponse_v3 & { isLoading: boolean };
  };
  prospectsByCampaign: { [campaignId: string]: CampaignProspects };
}

export const initialProspectsState: ProspectsState = {
  prospects: {},
  prospectsByCampaign: {},
};
