/* eslint-disable @typescript-eslint/no-redeclare */
import { StripeApi } from '@platform/integrations';
import { v4 as uuid } from 'uuid';
import type { Notification } from '../../types/notification';
import { ActionType } from '@platform/helpers';
import {
  ProspectsContactModalIsOpenPayload,
  ProspectsTagsModalIsOpenPayload,
  ProspectsNotesModalIsOpenPayload,
  ProspectInfoModalIsOpenPayload,
  ProspectAddressModalIsOpenPayload,
  DuplicateAddressModalIsOpenPayload,
  ProspectsNewAppointmentModalIsOpenPayload,
  EditTagModalOpenPayload,
  DeleteTagModalOpenPayload,
  HideTagModalOpenPayload,
} from '../state/app-state';

export const AppActions = {
  notification: (action: Omit<Notification, 'placement'> & { placement?: Notification['placement'] }) =>
    ({
      type: 'Notification/add',
      payload: { id: uuid(), ...action, placement: action.placement ?? 'upper-right' },
    } as const),
  removeNotification: () =>
    ({
      type: 'Notification/remove',
    } as const),
  removeNotificationById: (payload: string) =>
    ({
      type: 'Notification/byId',
      payload,
    } as const),
  setProspectsDetailsModal: (payload: { isOpen: boolean; campaignId: string | null; prospectId: string | null }) =>
    ({
      type: 'Modals/setProspectsDetailsModal',
      payload,
    } as const),
  setProspectsContactModal: (payload: ProspectsContactModalIsOpenPayload | null) =>
    ({
      type: 'Modals/setProspectsContactModal',
      payload,
    } as const),
  setProspectInfoModal: (payload: ProspectInfoModalIsOpenPayload | null) =>
    ({
      type: 'Modals/setProspectInfoModal',
      payload,
    } as const),
  setProspectAddressModal: (payload: ProspectAddressModalIsOpenPayload | null) =>
    ({
      type: 'Modals/setProspectAddressModal',
      payload,
    } as const),
  setDuplicateProspectModal: (payload: DuplicateAddressModalIsOpenPayload | null) =>
    ({
      type: 'Modals/setDuplicateProspectModal',
      payload,
    } as const),
  setProspectsTagsModal: (payload: ProspectsTagsModalIsOpenPayload | null) =>
    ({
      type: 'Modals/setProspectsTagsModal',
      payload,
    } as const),
  setBoostIndicatorModal: (payload: { isOpen: boolean }) =>
    ({
      type: 'Modals/setBoostIndicatorModal',
      payload,
    } as const),
  setTeamInviteModal: (payload: boolean) =>
    ({
      type: 'Modals/setTeamInviteModal',
      payload,
    } as const),
  setSubscriptionErrorModal: (payload: { subscriptionStatus: StripeApi.SubscriptionStatus } | null) =>
    ({
      type: 'Modals/setSubscriptionErrorModal',
      payload,
    } as const),
  setCompanyInviteModal: (payload: { isOpen: boolean }) =>
    ({
      type: 'Modals/setCompanyInviteModal',
      payload,
    } as const),
  setCampaignAssignmentModal: (
    payload:
      | {
          isOpen: true;
          campaignId: string;
          assignedUserId: number | null;
        }
      | { isOpen: false }
  ) =>
    ({
      type: 'Modals/setCampaignAssignmentModal',
      payload,
    } as const),
  setProspectsNotesModal: (payload: ProspectsNotesModalIsOpenPayload | null) =>
    ({
      type: 'Modals/setProspectsNotesModal',
      payload,
    } as const),
  setCompanyImpersonation: (payload: { companyId: string }) =>
    ({
      type: 'App/setCompanyImpersonation',
      payload,
    } as const),
  clearCompanyImpersonation: () =>
    ({
      type: 'App/clearCompanyImpersonation',
    } as const),
  didRedirectToGettingStarted: () =>
    ({
      type: 'App/didRedirectToGettingStarted',
    } as const),
  setSubscriptionStatusLoading: () =>
    ({
      type: 'App/setSubscriptionStatusLoading',
    } as const),
  setSubscriptionStatus: (payload: { status: StripeApi.SubscriptionStatus; lastChecked: Date | null }) =>
    ({
      type: 'App/setSubscriptionStatus',
      payload,
    } as const),
  setNewAppointmentModal: (payload: ProspectsNewAppointmentModalIsOpenPayload | null) =>
    ({
      type: 'Modals/setNewAppointmentModal',
      payload,
    } as const),
  setEditTagModalOpen: (payload: EditTagModalOpenPayload | null) =>
    ({
      type: 'Modals/setEditTagModal',
      payload,
    } as const),
  setCreateTagModalOpen: (open: boolean) =>
    ({
      type: 'Modals/setCreateTagModal',
      payload: { open },
    } as const),
  setHideTagModalOpen: (payload: HideTagModalOpenPayload | null) =>
    ({
      type: 'Modals/setHideTagModal',
      payload,
    } as const),
  setDeleteTagModalOpen: (payload: DeleteTagModalOpenPayload | null) =>
    ({
      type: 'Modals/setDeleteTagModal',
      payload,
    } as const),
};

export type AppActions = ActionType<typeof AppActions>;
