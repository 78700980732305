import { Logo, Spinner } from '@platform/ui';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { Constants, Plan, PlanName, SUBSCRIPTION_PLANS } from '@platform/app-config';
import { useCallback, useMemo } from 'react';

const { format: formatNoDecimal } = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});
const { format: formatWithDecimal } = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

type SubscriptionPlans = Extract<PlanName, 'STARTER_SCOUT' | 'PRO_SCOUT' | 'ENTERPRISE'>;

const formatCurrency = (value: number, withDecimalOverride = false) =>
  Number.isInteger(value / 100) && !withDecimalOverride ? formatNoDecimal(value / 100) : formatWithDecimal(value / 100);

const getPriceForPlan = (plan: SubscriptionPlans) =>
  formatCurrency(SUBSCRIPTION_PLANS[plan].platformFee + (SUBSCRIPTION_PLANS[plan].marketingCredits ?? 0));

const getTiers: (freeTrial: boolean) => Record<
  SubscriptionPlans,
  {
    name: string;
    description: string;
    price: string;
    mostPopular: boolean;
    features: string[];
    buttonText: string;
  }
> = (freeTrial) => ({
  STARTER_SCOUT: {
    name: 'Starter',
    buttonText: freeTrial ? 'Start for free' : 'Select',
    description: `Full access for up to ${SUBSCRIPTION_PLANS.STARTER_SCOUT.usersIncluded} users`,
    price: getPriceForPlan('STARTER_SCOUT'),
    mostPopular: false,
    features: [
      ...(freeTrial ? [`Try free for ${SUBSCRIPTION_PLANS.STARTER_SCOUT.freeTrialPeriod} days`] : []),
      'iOS and Android access',
      'Prospect names included',
    ],
  },
  PRO_SCOUT: {
    name: 'Pro Scout',
    buttonText: freeTrial ? 'Start for free' : 'Select',
    description: `Full access for up to ${SUBSCRIPTION_PLANS.PRO_SCOUT.usersIncluded} users`,
    price: getPriceForPlan('PRO_SCOUT'),
    mostPopular: true,
    features: [
      'Everything from Starter, plus...',
      `${SUBSCRIPTION_PLANS.PRO_SCOUT.usersIncluded} users included`,
      'Direct mail automations',
      'Add more than 1 location',
    ],
  },
  ENTERPRISE: {
    name: 'Enterprise',
    buttonText: freeTrial ? 'Start for free' : 'Select',
    description: `Full access for up to ${SUBSCRIPTION_PLANS.ENTERPRISE.usersIncluded} users`,
    price: getPriceForPlan('ENTERPRISE'),
    mostPopular: false,
    features: [
      'Everything from Pro Scout, plus...',
      `${SUBSCRIPTION_PLANS.ENTERPRISE.usersIncluded} users included`,
      `Enterprise grade Direct Mail add-on available`,
    ],
  },
});

const buildTier = (plan: SubscriptionPlans) => (fn: boolean | string | ((p: Plan) => string | boolean)) =>
  ({
    [plan as SubscriptionPlans]: typeof fn === 'function' ? fn(SUBSCRIPTION_PLANS[plan]) : fn,
  } as Record<SubscriptionPlans, string | boolean>);

const buildTiers = (
  fn: boolean | string | ((p: Plan) => string | boolean)
): Record<SubscriptionPlans, string | boolean> => ({
  ...buildTier('STARTER_SCOUT')(fn),
  ...buildTier('PRO_SCOUT')(fn),
  ...buildTier('ENTERPRISE')(fn),
});

const sections: {
  name: string;
  features: {
    name: string;
    tiers: Record<SubscriptionPlans, string | boolean>;
    finePrint?: { label: string; classNames?: string };
  }[];
}[] = [
  {
    name: 'D2D Tracking',
    features: [
      { name: 'Mobile Scouting App', finePrint: { label: '(iOS & Android)' }, tiers: buildTiers(true) },
      { name: 'Create & Assign Territories', tiers: buildTiers((p) => p.features.includes('campaigns')) },
      { name: 'Unlimited Addresses', tiers: buildTiers((p) => p.prospectsIncluded === 'unlimited') },
      { name: 'Prospect Names', tiers: buildTiers((p) => p.features.includes('homeowner_names')) },
      {
        name: 'Expanded Homeowner Data',
        tiers: buildTiers((p) => (p.features.includes('demographics') ? 'Ask Sales' : false)),
      },
      { name: 'Custom Property Tags', tiers: buildTiers(true) },
      { name: 'Collaborate with Notes', tiers: buildTiers(true) },
      { name: 'Control Automated Marketing', tiers: buildTiers((p) => p.programsIncluded !== null) },
      { name: 'KPI Dashboard', tiers: buildTiers(true) },
      { name: 'CSV Data Export', tiers: buildTiers(true) },
      {
        name: 'Team Onboarding',
        tiers: buildTiers((p) => (p.teamOnboardingExtra ? formatCurrency(p.teamOnboardingExtra) : 'Included')),
      },
    ],
  },
  {
    name: 'Team Collaboration',
    features: [
      {
        name: 'Seats per Team',
        tiers: buildTiers((p) => `Up to ${p.usersIncluded}`),
      },
      {
        name: 'Create & Assign Territories',
        tiers: buildTiers(true),
      },
      {
        name: 'Locations',
        tiers: buildTiers((p) => p.locationsIncluded.toString()),
      },
      {
        name: 'Additional Locations',
        tiers: buildTiers((p) => (p.locationsExtra ? `${formatCurrency(p.locationsExtra)}/mo` : false)),
      },
    ],
  },
  {
    name: 'Support & Training',
    features: [
      {
        name: 'On-Site Training',
        tiers: buildTiers('Ask Sales'),
      },
    ],
  },
];

export default function Content(props: {
  onSelect: (plan: SubscriptionPlans) => void;
  onClickContact: () => void;
  loading: SubscriptionPlans | null;
  freeTrial: boolean;
}) {
  const handleSelect = useCallback(
    (plan: SubscriptionPlans) => {
      if (!props.loading) {
        props.onSelect(plan);
      }
    },
    [props]
  );

  const tiers = useMemo(() => getTiers(props.freeTrial), [props.freeTrial]);

  return (
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <header className="flex flex-col items-center mb-8">
        <Logo height={40} color="#A32A2A" />
        <h1 className="text-4xl leading-9 text-gray-900 font-extrabold mt-8 mb-4">Affordable pricing for any team</h1>
        <p className="text-lg leading-5 font-normal text-gray-700 mb-8">
          Plans are built for your contractor business whether you're just starting or have a large team.
        </p>
      </header>
      <div className="isolate mx-auto mt-10 mb-6 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        {(Object.keys(tiers) as SubscriptionPlans[]).map((key) => {
          const tier = tiers[key];
          return (
            <div
              key={tier.name}
              className={classNames(
                tier.mostPopular ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-200',
                'rounded-3xl p-8 xl:p-10'
              )}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h3
                  id={tier.name}
                  className={classNames(
                    tier.mostPopular ? 'text-indigo-600' : 'text-gray-900',
                    'text-lg font-semibold leading-8'
                  )}
                >
                  {tier.name}
                </h3>
                {tier.mostPopular ? (
                  <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
                    Most popular
                  </p>
                ) : null}
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-gray-900">{tier.price}</span>
                <span className="text-sm font-semibold leading-6 text-gray-600">{'/mo'}</span>
              </p>
              <div
                onClick={() => handleSelect(key)}
                className={classNames(
                  {
                    'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500': tier.mostPopular,
                    'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300': !tier.mostPopular,
                    'cursor-pointer': !props.loading,
                  },
                  'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                )}
              >
                {props.loading === key ? <Spinner /> : tier.buttonText}
              </div>
              <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
      <div className="relative bg-gray-50 ">
        <div className="mx-auto max-w-7xl px-6 py-10 sm:py-12 lg:px-8">
          {/* Feature comparison (up to lg) */}
          <div className="mt-6 mb-12 text-center">
            <h1 className="text-4xl leading-9 text-gray-900 font-extrabold mb-4">Compare Plans</h1>
            <p className="text-lg leading-5 font-normal text-gray-700">
              We size plans to fit your business.{' '}
              <a
                href={Constants.DEMO_URL}
                className="text-indigo-600 cursor-pointer font-semibold"
                target="_blank"
                rel="noreferrer"
              >
                Talk to one of our sales experts
              </a>{' '}
              to learn more!
            </p>
          </div>
          <section aria-labelledby="mobile-comparison-heading" className="lg:hidden">
            <h2 id="mobile-comparison-heading" className="sr-only">
              Feature comparison
            </h2>

            <div className="mx-auto max-w-2xl space-y-16">
              {(Object.keys(tiers) as SubscriptionPlans[]).map((key) => {
                const tier = tiers[key];
                return (
                  <div key={tier.name} className="border-t border-gray-900/10">
                    <div
                      className={classNames(
                        tier.mostPopular ? 'border-indigo-600' : 'border-transparent',
                        '-mt-px w-72 border-t-2 pt-10 md:w-80'
                      )}
                    >
                      <h3
                        className={classNames(
                          tier.mostPopular ? 'text-indigo-600' : 'text-gray-900',
                          'text-sm font-semibold leading-6'
                        )}
                      >
                        {tier.name}
                      </h3>
                      <p className="mt-1 text-sm leading-6 text-gray-600">{tier.description}</p>
                    </div>

                    <div className="mt-10 space-y-10">
                      {sections.map((section) => (
                        <div key={section.name}>
                          <h4 className="text-sm font-semibold leading-6 text-gray-900">{section.name}</h4>
                          <div className="relative mt-6">
                            {/* Fake card background */}
                            <div
                              aria-hidden="true"
                              className="absolute inset-y-0 right-0 hidden w-1/2 rounded-lg bg-white shadow-sm sm:block"
                            />

                            <div
                              className={classNames(
                                tier.mostPopular ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-900/10',
                                'relative rounded-lg bg-white shadow-sm sm:rounded-none sm:bg-transparent sm:shadow-none sm:ring-0'
                              )}
                            >
                              <dl className="divide-y divide-gray-200 text-sm leading-6">
                                {section.features.map((feature) => (
                                  <div
                                    key={feature.name}
                                    className="flex items-center justify-between px-4 py-3 sm:grid sm:grid-cols-2 sm:px-0"
                                  >
                                    <dt className="pr-4 text-gray-600">
                                      {feature.name}
                                      {feature.finePrint && (
                                        <>
                                          <br />
                                          <span className={feature.finePrint.classNames ?? 'text-xs text-gray-500'}>
                                            {feature.finePrint.label}
                                          </span>
                                        </>
                                      )}
                                    </dt>
                                    <dd className="flex items-center justify-end sm:justify-center sm:px-4">
                                      {typeof feature.tiers[key] === 'string' ? (
                                        <span
                                          className={
                                            tier.mostPopular ? 'font-semibold text-indigo-600' : 'text-gray-900'
                                          }
                                        >
                                          {feature.tiers[key]}
                                        </span>
                                      ) : (
                                        <>
                                          {feature.tiers[key] === true ? (
                                            <CheckIcon className="mx-auto h-5 w-5 text-indigo-600" aria-hidden="true" />
                                          ) : (
                                            <XMarkIcon className="mx-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                                          )}

                                          <span className="sr-only">{feature.tiers[key] === true ? 'Yes' : 'No'}</span>
                                        </>
                                      )}
                                    </dd>
                                  </div>
                                ))}
                              </dl>
                            </div>

                            {/* Fake card border */}
                            <div
                              aria-hidden="true"
                              className={classNames(
                                tier.mostPopular ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-900/10',
                                'pointer-events-none absolute inset-y-0 right-0 hidden w-1/2 rounded-lg sm:block'
                              )}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          </section>

          {/* Feature comparison (lg+) */}
          <section aria-labelledby="comparison-heading" className="hidden lg:block">
            <h2 id="comparison-heading" className="sr-only">
              Feature comparison
            </h2>

            <div className="grid grid-cols-4 gap-x-8 border-t border-gray-900/10 before:block">
              {Object.values(tiers).map((tier) => (
                <div key={tier.name} aria-hidden="true" className="-mt-px">
                  <div
                    className={classNames(
                      tier.mostPopular ? 'border-indigo-600' : 'border-transparent',
                      'border-t-2 pt-10'
                    )}
                  >
                    <p
                      className={classNames(
                        tier.mostPopular ? 'text-indigo-600' : 'text-gray-900',
                        'text-sm font-semibold leading-6'
                      )}
                    >
                      {tier.name}
                    </p>
                    <p className="mt-1 text-sm leading-6 text-gray-600">{tier.description}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="-mt-6 space-y-16">
              {sections.map((section) => (
                <div key={section.name}>
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">{section.name}</h3>
                  <div className="relative -mx-8 mt-10">
                    {/* Fake card backgrounds */}
                    <div
                      className="absolute inset-x-8 inset-y-0 grid grid-cols-4 gap-x-8 before:block"
                      aria-hidden="true"
                    >
                      <div className="h-full w-full rounded-lg bg-white shadow-sm" />
                      <div className="h-full w-full rounded-lg bg-white shadow-sm" />
                      <div className="h-full w-full rounded-lg bg-white shadow-sm" />
                    </div>

                    <table className="relative w-full border-separate border-spacing-x-8">
                      <thead>
                        <tr className="text-left">
                          <th scope="col">
                            <span className="sr-only">Feature</span>
                          </th>
                          {Object.values(tiers).map((tier) => (
                            <th key={tier.name} scope="col">
                              <span className="sr-only">{tier.name} tier</span>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {section.features.map((feature, featureIdx) => (
                          <tr key={feature.name}>
                            <th
                              scope="row"
                              className="w-1/4 py-3 pr-4 text-left text-sm font-normal leading-6 text-gray-900"
                            >
                              {feature.name}
                              {feature.finePrint && (
                                <>
                                  <br />
                                  <span className={feature.finePrint.classNames ?? 'text-xs text-gray-500'}>
                                    {feature.finePrint.label}
                                  </span>
                                </>
                              )}
                              {featureIdx !== section.features.length - 1 ? (
                                <div className="absolute inset-x-8 mt-3 h-px bg-gray-200" />
                              ) : null}
                            </th>
                            {(Object.keys(tiers) as SubscriptionPlans[]).map((key) => {
                              const tier = tiers[key];
                              return (
                                <td key={tier.name} className="relative w-1/4 px-4 py-0 text-center">
                                  <span className="relative h-full w-full py-3">
                                    {typeof feature.tiers[key] === 'string' ? (
                                      <span
                                        className={classNames(
                                          tier.mostPopular ? 'font-semibold text-indigo-600' : 'text-gray-900',
                                          'text-sm leading-6'
                                        )}
                                      >
                                        {feature.tiers[key]}
                                      </span>
                                    ) : (
                                      <>
                                        {feature.tiers[key] === true ? (
                                          <CheckIcon className="mx-auto h-5 w-5 text-indigo-600" aria-hidden="true" />
                                        ) : (
                                          <XMarkIcon className="mx-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                                        )}

                                        <span className="sr-only">{feature.tiers[key] === true ? 'Yes' : 'No'}</span>
                                      </>
                                    )}
                                  </span>
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {/* Fake card borders */}
                    <div
                      className="pointer-events-none absolute inset-x-8 inset-y-0 grid grid-cols-4 gap-x-8 before:block"
                      aria-hidden="true"
                    >
                      {Object.values(tiers).map((tier) => (
                        <div
                          key={tier.name}
                          className={classNames(
                            tier.mostPopular ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-900/10',
                            'rounded-lg'
                          )}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
      <footer className="flex flex-col mt-8 bg-gray-100 p-12 rounded-lg">
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
          <div className="flex flex-col items-start">
            <div className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
              Need More?
            </div>
            <p className="text-lg leading-7 font-normal text-gray-600 mt-4">
              Contact us for a personalized plan fit just for you!
            </p>
          </div>
          <div className="flex mt-8 sm:mt-0 sm:ml-4">
            <button
              onClick={props.onClickContact}
              type="button"
              className="inline-flex items-center rounded-md border border-indigo-600 bg-gray-100 px-6 h-14 shadow-sm hover:bg-gray-200 active:bg-gray-200/75 focus:outline-none shrink-0"
            >
              <span className="text-base font-medium text-indigo-600">Contact Sales</span>
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
}
