import { ArrowTrendingUpIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/20/solid';
import { CalendarIcon, EnvelopeIcon, HandThumbUpIcon } from '@heroicons/react/24/outline';
import { Constants } from '@platform/app-config';
import { MailDisabled, RangeInput, SelectableBox, SelectMenu, Tooltip, TooltipContent } from '@platform/ui';
import { Item } from '@platform/ui/src/SelectMenu';
import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { Matcher } from 'react-day-picker';
import { LoadedProgram } from '../../../../redux/actions/program-actions';
import { MailDeliveryType } from '../../../../types/campaign';
import Calendar from '../../../Calendar';

export interface Props {
  programs: LoadedProgram[];
  onSelectProgram: (id: number) => void;
  selectedProgram: LoadedProgram | null;
  onSelectDate: (date: Date) => void;
  selectedDate: Date | null;
  mailDeliveryType: MailDeliveryType | null;
  onChangeMailDeliveryType: (type: MailDeliveryType) => void;
  onEnableMailProgram: (enabled: boolean) => void;
  isMailProgramEnabled: boolean | null;
  totalImpressions: number;
  disabledDates?: Matcher | Matcher[] | undefined;
  slider: {
    total: number;
    value: number;
    onChangeValue: (value: number) => void;
  };
}

const Slider = ({ total, value, onChangeValue }: Props['slider']) => {
  const numberOfProspects = value.toString();
  return (
    <div className="flex flex-col items-center md:justify-center relative h-10 ">
      <div className="flex items-center">
        <h2 className="text-xl font-semibold leading-7 text-gray-900 mr-2">
          <span
            className={classNames(
              {
                // this prevents the text to move on every number change
                'min-w-[54px]': numberOfProspects.length === 4,
                'min-w-[40px]': numberOfProspects.length === 3,
                'min-w-[28px]': numberOfProspects.length === 2,
                'min-w-[16px]': numberOfProspects.length === 1,
              },
              'inline-flex md:justify-end'
            )}
          >
            {value}
          </span>
          {value !== total && <span> of {total}</span>} Addresses
        </h2>
      </div>
      <div className="flex items-center h-11 mt-1 mx-auto w-full max-w-2xl">
        <RangeInput total={total} startValue={value} onChangeValue={onChangeValue} />
      </div>
    </div>
  );
};

const MailProgramUI = ({
  programs,
  onSelectProgram,
  selectedProgram,
  onSelectDate,
  selectedDate,
  mailDeliveryType,
  onChangeMailDeliveryType,
  onEnableMailProgram,
  isMailProgramEnabled,
  totalImpressions,
  disabledDates,
  slider,
}: Props) => {
  const programItems = useMemo(
    () => programs.map((p) => ({ id: p.id, text: p.activatedOn ? p.name : `${p.name} (Draft)` })) as Item[],
    [programs]
  );
  const selectedProgramItem = useMemo(
    () => (selectedProgram ? programItems.find((p) => p.id === selectedProgram.id) : null) as Item | null,
    [programItems, selectedProgram]
  );
  const onProgramSelected = useCallback(
    (program: Item) => {
      onSelectProgram(program.id);
    },
    [onSelectProgram]
  );

  const isDelay = mailDeliveryType === MailDeliveryType.DELAY;
  const isSchedule = mailDeliveryType === MailDeliveryType.SCHEDULE;
  return (
    <div className="flex flex-col bg-white shadow rounded-lg p-6">
      <div className="flex items-center mb-5">
        <div className="flex flex-col">
          <div className="flex items-center my-1">
            <h3 className="text-xl leading-7 text-gray-900 font-bold mr-2">Add Mail Automation</h3>
            <Tooltip
              bottom={28}
              buttonContent={<InformationCircleIcon className="h-6 w-6 text-gray-900" aria-hidden="true" />}
              content={
                <TooltipContent
                  content={[
                    {
                      text: 'Automations are strategically designed for each campaign strategy to boost impressions wherever your team is active.',
                    },
                  ]}
                  links={[
                    {
                      text: 'See how automations work',
                      target: '_blank',
                      href: Constants.HOW_EMAIL_WORKS_URL,
                    },
                  ]}
                />
              }
            />
          </div>
          <p className="text-sm leading-5 font-medium text-gray-500">
            Add an automation to trigger an automated drip series of postcards or handwritten notes to prospects within
            the campaign.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-5 mb-5">
        <SelectableBox
          onClick={() => onEnableMailProgram(true)}
          isSelected={isMailProgramEnabled === true}
          title="Add Mail"
          icon={
            <EnvelopeIcon
              className={classNames(
                {
                  'text-blue-500': isMailProgramEnabled,
                  'text-gray-400': !isMailProgramEnabled,
                },
                ' h-9 w-9'
              )}
              aria-hidden="true"
            />
          }
        />
        <SelectableBox
          onClick={() => onEnableMailProgram(false)}
          isSelected={isMailProgramEnabled === false}
          title="No Mail"
          icon={<MailDisabled color={isMailProgramEnabled === false ? '#3B82F6' : '#9CA3AF'} />}
        />
      </div>
      {isMailProgramEnabled && (
        <div className={'flex flex-col md:overflow-hidden transition-all duration-300 opacity-100 max-h-[1200px]'}>
          <div className="flex flex-col mb-5">
            <div className="flex flex-col md:max-w-sm mb-5">
              <SelectMenu
                title="Select an Automation"
                data={programItems}
                value={selectedProgramItem}
                onChange={onProgramSelected}
                placeholder={programs.length > 0 ? 'Select automation' : 'Default Automation'}
              />
            </div>
            <div className="w-full h-[1px] bg-gray-200 mt-5" />
          </div>

          <div className="flex flex-col mb-5">
            <div className="flex items-center mb-5">
              <h3 className="text-xl leading-7 text-gray-900 font-bold mr-2">Mail Delivery</h3>
              <Tooltip
                bottom={28}
                buttonContent={<InformationCircleIcon className="h-6 w-6 text-gray-900" aria-hidden="true" />}
                content={
                  <TooltipContent
                    content={[
                      {
                        text: 'Send mail to the list by scheduling it now, or wait to send mail until your team scouts each prospect.',
                      },
                    ]}
                    links={[
                      {
                        text: 'Find out more',
                        target: '_blank',
                        href: Constants.SCHEDULE_MAIL_DELIVERY,
                      },
                    ]}
                  />
                }
              />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
              <SelectableBox
                onClick={() => onChangeMailDeliveryType(MailDeliveryType.SCHEDULE)}
                isSelected={isSchedule}
                title="Start mail campaign"
                icon={
                  <CalendarIcon
                    className={classNames(
                      {
                        'text-blue-500': isSchedule,
                        'text-gray-400': !isSchedule,
                      },
                      ' h-9 w-9'
                    )}
                    aria-hidden="true"
                  />
                }
                description="Generate a list and set a delivery date - you can always stop mail later if desired"
              />
              <SelectableBox
                onClick={() => onChangeMailDeliveryType(MailDeliveryType.DELAY)}
                isSelected={isDelay}
                title="Wait until scouting"
                icon={<HandThumbUpIcon color={isDelay ? '#3B82F6' : '#9CA3AF'} className="h-9 w-9" />}
                description="Mail automation will begin when you scout a prospect"
              />
            </div>
          </div>
          {isSchedule && (
            <div className="flex flex-col space-y-4">
              {slider.total > Constants.DEFAULT_PROSPECT_COUNT_LIMIT ? (
                <div className="flex flex-col items-center md:justify-center">
                  <ExclamationTriangleIcon className="text-yellow-600 h-12 w-12" aria-hidden="true" />
                  <p className="text-sm leading-4 text-gray-500 font-semibold ml-1.5">
                    Campaign area must contain less than {Constants.DEFAULT_PROSPECT_COUNT_LIMIT} addresses.
                    <br />
                    Please update the campaign boundary on the map.
                  </p>
                </div>
              ) : (
                <>
                  <div className="flex flex-col my-8 mx-4">
                    <Slider {...slider} />
                    {totalImpressions > 0 && (
                      <div className="flex items-center self-center mt-5">
                        <ArrowTrendingUpIcon className="text-pink-500 h-5 w-5 mr-2" aria-hidden="true" />
                        <p className="text-sm leading-5 font-medium text-pink-500">
                          Up to {totalImpressions} Impressions
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="flex justify-center flex-col md:px-6 pb-6 self-center">
                    <h3 className="text-xl text-center leading-7 text-gray-900 font-bold mb-5">
                      Deliver the first mailing on
                    </h3>
                    <div className="mx-auto transform scale-90 sm:scale-100">
                      <Calendar onSelectDate={onSelectDate} selectedDate={selectedDate} disabled={disabledDates} />
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MailProgramUI;
