import { useAuth0 } from '@auth0/auth0-react';
import { ReceiptPercentIcon } from '@heroicons/react/24/outline';
import { PortalAPI } from '@platform/api';
import { HomeownerDataSubscription } from '@platform/model/dist/types/company';
import { BetaPill } from '@platform/ui';
import axios, { AxiosError } from 'axios';
import classNames from 'classnames';
import { upperFirst } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppActions } from '../../redux/actions/app-actions';
import { Selectors } from '../../redux/selectors';
import { useTypedDispatch } from '../../redux/state';
import { NotificationType } from '../../types/notification';
import { withAuthenticatedPageLayout } from '../Layout/authenticated-page';
import { PageHeader } from '../Layout/PageHeader';
import { Spinner } from '../Spinner';

export enum Step {
  update = 'UPDATE',
  view = 'VIEW',
}

const Loading = (p: { rows?: number }) => (
  <div className="flex flex-col space-y-2">
    {Array.from({ length: p.rows ?? 1 }).map((_, i) => (
      <div key={i} className="h-4 bg-gray-100 rounded w-1/6 block animate-pulse" />
    ))}
  </div>
);

const demographicDataDisplay: Record<HomeownerDataSubscription, string> = {
  none: 'None',
  names: 'Prospect Names',
  all: 'Prospect Names + Demographics',
};

export const Billing = withAuthenticatedPageLayout(() => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useTypedDispatch();
  const user = useSelector(Selectors.user);
  const company = useSelector(Selectors.company);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [step, setStep] = useState<Step>();
  const [subscriptionDetails, setSubscriptionDetails] = useState<
    PortalAPI.AuthAPI.Responses['GET /stripe-retrieve-subscription']
  >({ productName: '', paymentInterval: null });

  useEffect(() => {
    const getStripeSubscription = async () => {
      try {
        const { data } = await PortalAPI.Client.getApiClient(getAccessTokenSilently).request({
          route: 'GET /stripe-retrieve-subscription',
        });

        setSubscriptionDetails(data);
      } catch {}
    };

    if (!user.isLoading) {
      getStripeSubscription();
    }
  }, [user.isLoading, getAccessTokenSilently]);

  // This needs to be an onClick Event because the Stripe Url has an expired and needs to create a new url each time
  const onRedirectToCustomerPortal = useCallback(
    async (step: Step) => {
      try {
        setIsSubmitting(true);
        setStep(step);
        const { status, data } = await PortalAPI.Client.getApiClient(getAccessTokenSilently).request<
          typeof PortalAPI.AuthAPI.API['GET /stripe-customer-portal'],
          PortalAPI.AuthAPI.QueryParams['GET /stripe-customer-portal']
        >({
          route: PortalAPI.AuthAPI.API['GET /stripe-customer-portal'],
          queryParams: {
            return_url: window.location.href,
            type: step === Step.update ? 'payment_method_update' : undefined,
          },
        });

        if (status === 200 && !('error' in data)) {
          window.location.href = data.url;
        }
      } catch (e) {
        let error: string | null = null;
        if (axios.isAxiosError(e)) {
          const res = ((e ?? {}) as AxiosError<PortalAPI.AuthAPI.Responses['GET /stripe-customer-portal']>)?.response;
          if (res && 'error' in res.data) {
            error = res.data.error;
          }
        }

        dispatch(
          AppActions.notification({
            type: NotificationType.error,
            title: 'Could not launch Billing Portal',
            description: error ?? 'Please contact support@leadscoutapp.com for assistance.',
          })
        );
      } finally {
        setIsSubmitting(false);
      }
    },
    [dispatch, getAccessTokenSilently]
  );

  const redirectButtonClasses = classNames(
    { 'pointer-events-none opacity-60': isSubmitting },
    'inline-flex items-center font-medium text-blue-600  hover:text-blue-500 cursor-pointer'
  );

  return (
    <div>
      <PageHeader title="Billing & Subscription" icon={ReceiptPercentIcon} iconHeading="Billing" />

      <div className="lg:px-8 flex flex-col mt-5 mx-4 sm:mx-6 lg:max-w-6xl lg:mx-auto">
        <div className="bg-white shadow overflow-hidden rounded-lg">
          <div className="flex-1 flex items-center">
            <div className="px-4 py-5 sm:px-6 flex-grow">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Subscription</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">Manage your subscription details</p>
            </div>
            <div className="flex mr-8">
              <div className={redirectButtonClasses}>Manage</div>
            </div>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-2 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Subscription</dt>

                {company.isLoading || !subscriptionDetails.productName ? (
                  <Loading rows={2} />
                ) : (
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex flex-col sm:flex-row">
                    <div className="flex flex-col text-gray-900 leading-6">
                      <div className="font-semibold">
                        {subscriptionDetails.productName}
                        {subscriptionDetails.paymentInterval
                          ? ` (${upperFirst(subscriptionDetails.paymentInterval)})`
                          : ''}
                      </div>
                      {!!company.usersIncluded && (
                        <div>
                          {company.usersIncluded} User{company.usersIncluded > 1 ? 's' : ''}
                        </div>
                      )}
                      {!!company.homeownerDataSubscription &&
                        company.homeownerDataSubscription !== HomeownerDataSubscription.NONE && (
                          <div className="flex items-center">
                            <div className="flex-shrink mr-2">
                              {demographicDataDisplay[company.homeownerDataSubscription]}
                            </div>
                            <BetaPill />
                          </div>
                        )}
                    </div>
                  </dd>
                )}
              </div>
              {!!company.usersIncluded && company.usersIncluded > 1 && (
                <div className="py-2 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Users</dt>
                  {company.isLoading ? (
                    <Loading />
                  ) : !!company.usersIncluded ? (
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex flex-col sm:flex-row">
                      <div className="flex-shrink mr-4">
                        {company.users} of {Math.max(company.usersIncluded!, company.users ?? 0)} seats filled
                      </div>
                    </dd>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </dl>
          </div>
        </div>
      </div>
      <div className="lg:px-8 mt-4 mx-4 sm:mx-6 lg:max-w-6xl lg:mx-auto">
        <div className="bg-white shadow overflow-hidden rounded-lg flex-1 flex items-center">
          <div className="px-4 py-5 sm:px-6 flex-grow">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Payment Method</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Manage your payment methods</p>
          </div>
          <div className="flex mr-8">
            <button
              onClick={() => onRedirectToCustomerPortal(Step.update)}
              className={redirectButtonClasses}
              disabled={isSubmitting}
            >
              {isSubmitting && step === Step.update && <Spinner className="text-blue-600" />}
              Update
            </button>
          </div>
        </div>
      </div>
      <div className="lg:px-8 mt-4 mx-4 sm:mx-6 lg:max-w-6xl lg:mx-auto">
        <div className="bg-white shadow overflow-hidden rounded-lg flex-1 flex items-center">
          <div className="px-4 py-5 sm:px-6 flex-grow">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Billing Details</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">View an overview of your billing information</p>
          </div>
          <div className="flex mr-8">
            <button
              onClick={() => onRedirectToCustomerPortal(Step.view)}
              className={redirectButtonClasses}
              disabled={isSubmitting}
            >
              {isSubmitting && step === Step.view && <Spinner className="text-blue-600" />}
              View
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});
