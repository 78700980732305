"use strict";
exports.__esModule = true;
exports.getAddressFromContext = void 0;
var addresser_1 = require("addresser");
var getAddressFromContext = function (suggestion) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var parsed = null;
    try {
        parsed = (0, addresser_1.parseAddress)(suggestion.full_address);
    }
    catch (_q) { }
    var address = (_c = (_b = (_a = suggestion.context.address) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : 
    // parseAddress does return 'addressLine2' but it's not in the types
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (parsed ? (parsed === null || parsed === void 0 ? void 0 : parsed.addressLine1) + (parsed.addressLine2 ? " ".concat(parsed.addressLine2) : '') : null)) !== null && _c !== void 0 ? _c : suggestion.address;
    var city = (_h = (_g = (_e = (_d = suggestion.context.place) === null || _d === void 0 ? void 0 : _d.name) !== null && _e !== void 0 ? _e : (_f = suggestion.context.locality) === null || _f === void 0 ? void 0 : _f.name) !== null && _g !== void 0 ? _g : parsed === null || parsed === void 0 ? void 0 : parsed.placeName) !== null && _h !== void 0 ? _h : '';
    var state = (_l = (_k = (_j = suggestion.context.region) === null || _j === void 0 ? void 0 : _j.region_code) !== null && _k !== void 0 ? _k : parsed === null || parsed === void 0 ? void 0 : parsed.stateAbbreviation) !== null && _l !== void 0 ? _l : '';
    var zip = (_p = (_o = (_m = suggestion.context.postcode) === null || _m === void 0 ? void 0 : _m.name) !== null && _o !== void 0 ? _o : parsed === null || parsed === void 0 ? void 0 : parsed.zipCode) !== null && _p !== void 0 ? _p : '';
    return {
        address: address,
        city: city,
        state: state,
        zip: zip,
        formatted: !!address && city && state && zip ? "".concat(address, ", ").concat(city, ", ").concat(state, " ").concat(zip) : suggestion.full_address
    };
};
exports.getAddressFromContext = getAddressFromContext;
