import { useDebounce } from 'use-debounce';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';

export type UseSearchProps<T, D extends { search: string; data: T[] }> = {
  onSearch: (text: string) => Promise<D | null>;
  debounce: { ms: number; maxWaitMs: number };
};
type UseSearchInjectedProps<T> = {
  onValueChanged: (v: string) => void;
  onClear: () => void;
  searchIsFetching: boolean;
  searchError: string | null;
  searchResults: {
    searchTerm: string;
    results: T[];
  };
};

export const useSearch = <T, D extends { search: string; data: T[] }>({
  onSearch,
  debounce,
}: UseSearchProps<T, D>): UseSearchInjectedProps<T> => {
  const [value, setValue] = useState('');
  const [debouncedSearch] = useDebounce(value, debounce.ms, { maxWait: debounce.maxWaitMs });
  const [searchIsFetching, setSearchFetching] = useState(false);
  const [searchError, setSearchError] = useState<string | null>(null);
  const searchRequestCounter = useRef(0) as MutableRefObject<number>;
  const latestSearchCompletedCount = useRef(0) as MutableRefObject<number>;
  const [searchResultsRaw, setSearchResultsRaw] = useState<D | null>(null);

  const onValueChanged: UseSearchInjectedProps<T>['onValueChanged'] = useCallback((value: string) => {
    setValue(value);
  }, []);

  const onClear: UseSearchInjectedProps<T>['onClear'] = useCallback(() => {
    onValueChanged('');
    setSearchResultsRaw(null);
    setSearchFetching(false);
    setSearchError(null);
    searchRequestCounter.current = 0;
    latestSearchCompletedCount.current = 0;
  }, [onValueChanged]);

  useEffect(() => {
    if (debouncedSearch.length >= 2 || (latestSearchCompletedCount.current && debouncedSearch.length > 1)) {
      const search = (text: string, count: number) => {
        setSearchFetching((s) => (searchRequestCounter.current === count ? true : s));
        onSearch(text)
          .then((d) => {
            if (count > latestSearchCompletedCount.current) {
              latestSearchCompletedCount.current = count;
              setSearchError(null);
              console.log(d);
              setSearchResultsRaw(d);
            }
          })
          .catch((e) => {
            const error = `Failed to search for "${text}". ${e.message ?? e.error ?? e.toString()}`;
            console.error(error);
            if (searchRequestCounter.current === count) {
              setSearchError(error);
            }
          })
          .finally(() => {
            setSearchFetching((s) => (searchRequestCounter.current === count ? false : s));
          });
      };

      search(debouncedSearch, ++searchRequestCounter.current);
    }
  }, [debouncedSearch, onSearch]);

  // Handle input cleared
  useEffect(() => {
    if (value === '') {
      onClear();
    }
  }, [value, onClear]);

  const searchResults: UseSearchInjectedProps<T>['searchResults'] = useMemo(() => {
    if (searchResultsRaw === null) {
      return {
        searchTerm: '',
        results: [],
      };
    }

    console.log(searchResultsRaw);
    return {
      searchTerm: searchResultsRaw.search,
      results: searchResultsRaw.data,
    };
  }, [searchResultsRaw]);

  return {
    onValueChanged,
    onClear,
    searchResults,
    searchIsFetching,
    searchError,
  };
};
