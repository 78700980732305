import { GeoAPI } from '@platform/api';
import { Constants } from '@platform/app-config';
import { StripeApi } from '@platform/integrations';
import { Feature, Point } from 'geojson';
import type { NotificationAction } from '../../types/notification';
import { getSubscriptionStatusTimestamp } from '../../utils/subscription-error';

export interface ProspectsContactModalIsOpenPayload {
  prospectId: string;
}

export interface ProspectAddressModalIsOpenPayload {
  prospectId: string;
}

export interface DuplicateAddressModalIsOpenPayload {
  duplicateProspectId: string;
  addressLine1: string;
  addressLine2: string;
  prospect: Feature<Point, GeoAPI.ProspectGeoJsonProperties>;
  allowChangeLocation?: Point;
}

export interface ProspectsTagsModalIsOpenPayload {
  prospectId: string;
  tags: { externalId: string; value: string }[];
}

export interface ProspectInfoModalIsOpenPayload {
  prospectId: string;
}

export interface ProspectsNotesModalIsOpenPayload {
  prospectId: string;
  notes: string;
}

export interface ProspectsNewAppointmentModalIsOpenPayload {
  prospectId: string;
  appointmentTime: Date | null | undefined;
}

export interface EditTagModalOpenPayload {
  externalId: string;
  value: string;
  prospectCount: number;
  isHidden?: boolean;
}

export interface DeleteTagModalOpenPayload {
  externalId: string;
  value: string;
  isHidden?: boolean;
  prospectCount: number;
  onCallback?: () => void;
}

export interface HideTagModalOpenPayload {
  externalId: string;
  value: string;
  isHidden?: boolean;
  prospectCount: number;
}

export type AppState = {
  companyImpersonation: { id: string; time: Date } | null;
  didRedirectToGettingStarted: boolean;
  notifications: NotificationAction[];
  prospectsDetailsModalIsOpen: {
    campaignId: string;
    prospectId: string;
    boostIsOpen: boolean;
    infoIsOpen: boolean;
    contactIsOpen: boolean;
    addressIsOpen: boolean;
  } | null;
  prospectsTagsModalIsOpen: ProspectsTagsModalIsOpenPayload | null;
  duplicateAddressModalIsOpen: DuplicateAddressModalIsOpenPayload | null;
  teamInviteModalIsOpen: boolean;
  companyInviteModalIsOpen: boolean;
  campaignAssignmentModal: {
    campaignId: string;
    assignedUserId: number | null;
  } | null;
  subscriptionErrorModalIsOpen: { subscriptionStatus: StripeApi.SubscriptionStatus } | null;
  prospectsNotesModalIsOpen: ProspectsNotesModalIsOpenPayload | null;
  subscriptionStatus: {
    isLoading: boolean;
    status: StripeApi.SubscriptionStatus | null;
    lastChecked: Date | null;
  };
  prospectsNewAppointmentModalIsOpen: ProspectsNewAppointmentModalIsOpenPayload | null;
  editTagModalIsOpen: EditTagModalOpenPayload | null;
  createTagModalIsOpen: boolean;
  deleteTagModalIsOpen: DeleteTagModalOpenPayload | null;
  hideTagModalIsOpen: HideTagModalOpenPayload | null;
};

export const initialAppState: AppState = {
  companyImpersonation: window.sessionStorage.getItem(Constants.IMPERSONATE_COMPANY_ID_HEADER)
    ? (JSON.parse(
      window.sessionStorage.getItem(Constants.IMPERSONATE_COMPANY_ID_HEADER)!
    ) as AppState['companyImpersonation'])
    : null,
  notifications: [],
  prospectsDetailsModalIsOpen: null,
  prospectsTagsModalIsOpen: null,
  duplicateAddressModalIsOpen: null,
  campaignAssignmentModal: null,
  teamInviteModalIsOpen: false,
  companyInviteModalIsOpen: false,
  didRedirectToGettingStarted: false,
  subscriptionErrorModalIsOpen: null,
  prospectsNotesModalIsOpen: null,
  subscriptionStatus: {
    isLoading: false,
    lastChecked: getSubscriptionStatusTimestamp(),
    status: null,
  },
  prospectsNewAppointmentModalIsOpen: null,
  editTagModalIsOpen: null,
  createTagModalIsOpen: false,
  hideTagModalIsOpen: null,
  deleteTagModalIsOpen: null,
};
