export var CompanyAssociationPermission;
(function (CompanyAssociationPermission) {
    CompanyAssociationPermission["READ"] = "READ";
    CompanyAssociationPermission["WRITE"] = "WRITE";
    CompanyAssociationPermission["ALL"] = "ALL";
})(CompanyAssociationPermission || (CompanyAssociationPermission = {}));
export var HomeownerDataSubscription;
(function (HomeownerDataSubscription) {
    HomeownerDataSubscription["ALL"] = "all";
    HomeownerDataSubscription["NAMES"] = "names";
    HomeownerDataSubscription["NONE"] = "none";
})(HomeownerDataSubscription || (HomeownerDataSubscription = {}));
