import { useCallback, useEffect, useRef, useState } from 'react';
import { Drawer, ModalHeaderToolbar } from '@platform/ui';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/state';
import { Selectors } from '../../redux/selectors';
import { GetAccessTokenFunction } from '../../types/auth0';
import { updateProspectNotes } from '../../redux/thunks';
import { AppActions } from '../../redux/actions/app-actions';

export const ProspectNotesModal = ({ getAccessTokenSilently }: { getAccessTokenSilently: GetAccessTokenFunction }) => {
  const modalProps = useSelector(Selectors.getProspectsNotesModal);
  const dispatch = useTypedDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notes, setNotes] = useState('');
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (modalProps?.notes) {
      setNotes(modalProps.notes);
    }
  }, [modalProps]);

  const onClose = useCallback(() => {
    dispatch(AppActions.setProspectsNotesModal(null));
    setNotes('');
  }, [dispatch]);

  const handleSaveNotes = useCallback(async () => {
    if (!modalProps?.prospectId) {
      return { error: 'Unknown error' };
    }
    setIsSubmitting(true);
    return dispatch(updateProspectNotes(getAccessTokenSilently, notes, modalProps.prospectId)).finally(() => {
      setIsSubmitting(false);
      onClose();
    });
  }, [dispatch, getAccessTokenSilently, modalProps, notes, onClose]);

  const focusInput = useCallback(() => {
    // Only focus the input if the note is currently empty
    if (!modalProps?.notes) {
      inputRef.current?.focus();
    }
  }, [modalProps]);

  return (
    <Drawer isOpen={!!modalProps} onClose={onClose} onDidPresent={focusInput}>
      <ModalHeaderToolbar
        onBack={onClose}
        onSave={handleSaveNotes}
        title={!modalProps || modalProps?.notes === '' ? 'Add Note' : 'Edit Note'}
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
        hasShadow
      />
      <div className="flex flex-col p-6 bg-white">
        <textarea
          ref={inputRef}
          id="notes"
          rows={4}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          autoCapitalize="on"
          disabled={isSubmitting}
          className="whitespace-pre-wrap text-left block w-full px-3 py-2 mt-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm disabled:bg-slate-100 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
        >
          {notes}
        </textarea>
      </div>
    </Drawer>
  );
};
