"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.DeprecatedRouteDefinitions = exports.RouteDefinitions = exports.DeprecatedAPI = exports.API = void 0;
var helpers_1 = require("@platform/helpers");
var API;
(function (API) {
    API["GET /v3/prospects"] = "GET /v3/prospects";
    API["POST /v3/prospects"] = "POST /v3/prospects";
    API["GET /v3/prospects/:externalId"] = "GET /v3/prospects/:externalId";
    API["DELETE /v3/prospects/:externalId"] = "DELETE /v3/prospects/:externalId";
    API["POST /v1/prospects/:externalId/lead-status"] = "POST /v1/prospects/:externalId/lead-status";
    API["GET /v1/prospects/map/bounds"] = "GET /v1/prospects/map/bounds";
    API["PATCH /v2/prospects/:externalId/mail"] = "PATCH /v2/prospects/:externalId/mail";
    API["PATCH /v2/prospects/:externalId/contact"] = "PATCH /v2/prospects/:externalId/contact";
    API["PATCH /v1/prospects/:externalId/address"] = "PATCH /v1/prospects/:externalId/address";
    API["POST /v1/prospects/:externalId/address-check"] = "POST /v1/prospects/:externalId/address-check";
    API["POST /v2/prospects/:externalId/assignment"] = "POST /v2/prospects/:externalId/assignment";
    API["DELETE /v3/prospects/:externalId/tags/:tagExternalId"] = "DELETE /v3/prospects/:externalId/tags/:tagExternalId";
    API["POST /v3/prospects/:externalId/tags"] = "POST /v3/prospects/:externalId/tags";
    API["POST /v2/prospects/:externalId/notes"] = "POST /v2/prospects/:externalId/notes";
    API["POST /v1/prospects/:externalId/data-enrich"] = "POST /v1/prospects/:externalId/data-enrich";
    API["POST /v1/prospects/search-summary"] = "POST /v1/prospects/search-summary";
    API["GET /v1/prospects/search-summary/:suggestionId"] = "GET /v1/prospects/search-summary/:suggestionId";
    API["POST /v1/prospects/summary"] = "POST /v1/prospects/summary";
    API["POST /v1/prospects/:externalId/appointment"] = "POST /v1/prospects/:externalId/appointment";
    API["DELETE /v1/prospects/:externalId/appointment"] = "DELETE /v1/prospects/:externalId/appointment";
    // Deprecate 3.8.0
    API["GET /v2/prospects/tags"] = "GET /v2/prospects/tags";
})(API = exports.API || (exports.API = {}));
var DeprecatedAPI;
(function (DeprecatedAPI) {
    // Deprecate 3.5.4
    DeprecatedAPI["DELETE /v2/prospects/:externalId"] = "DELETE /v2/prospects/:externalId";
})(DeprecatedAPI = exports.DeprecatedAPI || (exports.DeprecatedAPI = {}));
exports.RouteDefinitions = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (0, helpers_1.authenticatedRoute)(API['GET /v3/prospects'])), (0, helpers_1.authenticatedRoute)(API['POST /v3/prospects'])), (0, helpers_1.authenticatedRoute)(API['GET /v3/prospects/:externalId'])), (0, helpers_1.authenticatedRoute)(API['DELETE /v3/prospects/:externalId'])), (0, helpers_1.authenticatedRoute)(API['POST /v1/prospects/:externalId/lead-status'])), (0, helpers_1.authenticatedRoute)(API['GET /v1/prospects/map/bounds'])), (0, helpers_1.authenticatedRoute)(API['PATCH /v2/prospects/:externalId/mail'])), (0, helpers_1.authenticatedRoute)(API['PATCH /v2/prospects/:externalId/contact'])), (0, helpers_1.authenticatedRoute)(API['PATCH /v1/prospects/:externalId/address'])), (0, helpers_1.authenticatedRoute)(API['POST /v1/prospects/:externalId/address-check'])), (0, helpers_1.authenticatedRoute)(API['POST /v2/prospects/:externalId/assignment'])), (0, helpers_1.authenticatedRoute)(API['POST /v2/prospects/:externalId/notes'])), (0, helpers_1.authenticatedRoute)(API['DELETE /v3/prospects/:externalId/tags/:tagExternalId'])), (0, helpers_1.authenticatedRoute)(API['POST /v3/prospects/:externalId/tags'])), (0, helpers_1.authenticatedRoute)(API['POST /v1/prospects/:externalId/data-enrich'])), (0, helpers_1.authenticatedRoute)(API['POST /v1/prospects/search-summary'])), (0, helpers_1.authenticatedRoute)(API['GET /v1/prospects/search-summary/:suggestionId'])), (0, helpers_1.authenticatedRoute)(API['POST /v1/prospects/summary'])), (0, helpers_1.authenticatedRoute)(API['POST /v1/prospects/:externalId/appointment'])), (0, helpers_1.authenticatedRoute)(API['DELETE /v1/prospects/:externalId/appointment'])), (0, helpers_1.authenticatedRoute)(API['GET /v2/prospects/tags']));
exports.DeprecatedRouteDefinitions = __assign({}, (0, helpers_1.authenticatedRoute)(DeprecatedAPI['DELETE /v2/prospects/:externalId']));
