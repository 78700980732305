"use strict";
exports.__esModule = true;
exports.calculateLengthOfResidence = exports.calculateAgeOfHome = exports.getNetWorthRange = void 0;
var integrations_1 = require("@platform/integrations");
var getNetWorthRange = function (netWorth) {
    return netWorth ? integrations_1.Cole.NetWorthRanges[netWorth] : 'Unknown';
};
exports.getNetWorthRange = getNetWorthRange;
var calculateAgeOfHome = function (yearBuilt) {
    if (!yearBuilt || yearBuilt > new Date().getFullYear()) {
        return 'Unknown';
    }
    return "".concat(new Date().getFullYear() - yearBuilt, " years");
};
exports.calculateAgeOfHome = calculateAgeOfHome;
var calculateLengthOfResidence = function (lengthOfResidence) {
    return lengthOfResidence ? "".concat(lengthOfResidence, " years") : 'Unknown';
};
exports.calculateLengthOfResidence = calculateLengthOfResidence;
