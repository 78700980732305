/* eslint-disable @typescript-eslint/no-redeclare */
import { ActionType } from '@platform/helpers';
import { PortalAPI } from '@platform/api';
import { LeadStatusIcon, LeadStatusType } from '@platform/helpers';

export const ProspectsActions = {
  campaignLoaded: (
    campaignId: string,
    prospects: PortalAPI.ProspectAPI.ProspectResponse_v3[],
    page: number,
    totalCount: number
  ) =>
  ({
    type: 'Prospects/campaign-loaded',
    payload: {
      campaignId,
      page,
      totalCount,
      prospects,
    },
  } as const),
  prospectLoaded: (payload: { id: string; data: PortalAPI.ProspectAPI.ProspectResponse_v3 | null }) =>
  ({
    type: 'Prospects/loaded',
    payload,
  } as const),
  prospectLoading: (payload: { externalId: string }) =>
  ({
    type: 'Prospects/loading',
    payload,
  } as const),
  setProspectLeadStatus: (payload: {
    leadStatus: { id: number; value: string; icon: LeadStatusIcon; type: LeadStatusType | null };
    prospectId: string;
    userId?: number;
  }) =>
  ({
    type: 'Prospects/setProspectLeadStatus',
    payload,
  } as const),
  setProspectNotes: (payload: {
    notes: string;
    notesUpdatedByUserName: string;
    notesUpdatedAt: Date;
    prospectId: string;
  }) =>
  ({
    type: 'Prospects/setProspectNotes',
    payload,
  } as const),
  setProspectMailStatus: (payload: { status: 'start' | 'stop'; prospectId: string }) =>
  ({
    type: 'Prospects/setProspectMailStatus',
    payload,
  } as const),
  clear: () =>
  ({
    type: 'Prospects/clear',
  } as const),
  removeProspect: (payload: { prospectId: string; campaignId: string }) =>
  ({
    type: 'Prospects/removeProspect',
    payload,
  } as const),
  setProspectTags: (payload: { tags: Omit<PortalAPI.ProspectAPI.ProspectResponse_v3['prospectTags'][number], 'id'>[]; prospectId: string }) =>
  ({
    type: 'Prospects/setProspectTags',
    payload,
  } as const),
  removeProspectTag: (payload: { tagId: string; prospectId: string }) =>
  ({
    type: 'Prospects/removeProspectTag',
    payload,
  } as const),
  setProspectContact: (payload: PortalAPI.ProspectAPI.ProspectResponse_v3) =>
  ({
    type: 'Prospects/setProspectContact',
    payload,
  } as const),
  setProspectAddress: (payload: PortalAPI.ProspectAPI.ProspectResponse_v3) =>
  ({
    type: 'Prospects/setProspectAddress',
    payload,
  } as const),
  setProspectAssignedTo: (payload: {
    assignedTo: PortalAPI.ProspectAPI.Responses['POST /v2/prospects/:externalId/assignment'];
    prospectId: string;
  }) =>
  ({
    type: 'Prospects/setProspectAssignedTo',
    payload,
  } as const),
  setProspectAppointment: (payload: { appointmentTime?: Date | null; prospectId: string }) =>
  ({
    type: 'Prospects/setProspectAppointment',
    payload,
  } as const),
  removeProspectAppointment: (payload: { prospectId: string }) =>
  ({
    type: 'Prospects/removeProspectAppointment',
    payload,
  } as const),
};

export type ProspectsActions = ActionType<typeof ProspectsActions>;
