"use strict";
exports.__esModule = true;
exports.getMediumDeliveryDays = void 0;
var assert_unreachable_1 = require("./assert-unreachable");
var types_1 = require("./types");
var getMediumDeliveryDays = function (medium) {
    switch (medium) {
        case types_1.CampaignMedium.POSTCARD:
            return 8;
        case types_1.CampaignMedium.NOTE:
            return 6;
        case types_1.CampaignMedium.SURVEY:
            return 14;
        default:
            return (0, assert_unreachable_1.assertUnreachableSafe)(medium) || 8;
    }
};
exports.getMediumDeliveryDays = getMediumDeliveryDays;
