import * as uuid from 'uuid';
import { Constants } from '@platform/app-config';
import {
  AssignedToCard,
  ProspectDetail,
  ProspectDetailProps,
  ProspectNotes,
  TagsGroup,
  HomeDataProps,
} from '@platform/ui';
import { Utils } from '@platform/ui-helpers';
import buildUrl from 'build-url';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppActions } from '../../redux/actions/app-actions';
import { Selectors } from '../../redux/selectors';
import { useTypedDispatch } from '../../redux/state';
import {
  deleteProspectTag,
  loadAllTags,
  updateProspectLeadStatus,
  updateProspectScoutedBy,
  updateProspectTags,
} from '../../redux/thunks';
import { GetAccessTokenFunction } from '../../types/auth0';
import { startMail, stopMail } from '../../utils/prospects';
import { PortalAPI } from '@platform/api';
import { differenceBy } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';

interface Props {
  prospect: PortalAPI.ProspectAPI.ProspectResponse_v3 | null;
  hasDemographicData: boolean;
  getAccessToken: GetAccessTokenFunction;
  campaignId: string;
  onDelete: (id: string) => void;
  onExit: () => void;
  deleteInProgress: boolean;
  userId?: number;
  email?: string;
}

const Content = ({
  prospect,
  hasDemographicData,
  getAccessToken,
  campaignId,
  onDelete,
  deleteInProgress,
  onExit,
  userId,
  email,
}: Props) => {
  const dispatch = useTypedDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { leadStatuses } = useSelector(Selectors.leadStatuses);
  const { user } = useSelector(Selectors.user);
  const currentTags = useSelector(Selectors.getTags);

  const leadStatusSliderData = useMemo(
    () =>
      leadStatuses.map(({ id, value, icon, isMailTrigger }) => ({
        id,
        icon,
        label: value,
        isMailTrigger,
      })),
    [leadStatuses]
  );

  const handleLeadStatusUpdate = useCallback(
    async (args: { externalId: string; leadStatusId: number }) => {
      if (!prospect) {
        return;
      }
      await dispatch(updateProspectLeadStatus(getAccessToken, args.leadStatusId, args.externalId, campaignId, userId));
    },
    [campaignId, dispatch, getAccessToken, prospect, userId]
  );

  const onOpenUrl: ProspectDetailProps['onOpenUrl'] = useCallback((url) => {
    window.open(url, '_blank');
  }, []);

  const handleBoostClicked: ProspectDetailProps['handleBoostClicked'] = useCallback(() => {
    dispatch(AppActions.setBoostIndicatorModal({ isOpen: true }));
  }, [dispatch]);

  const handleUpdateMailStatus: ProspectDetailProps['handleUpdateMailStatus'] = useCallback(
    async ({ action, externalId }) => {
      if (action === 'start') {
        startMail(dispatch, getAccessToken, externalId);
      } else {
        stopMail(dispatch, getAccessToken, externalId);
      }
    },
    [dispatch, getAccessToken]
  );

  const leadStatusMailTriggers = useMemo(() => {
    return leadStatusSliderData.reduce<Record<number, 'start' | 'stop'>>((acc, status) => {
      acc[status.id] = status.isMailTrigger ? 'start' : 'stop';
      return acc;
    }, {});
  }, [leadStatusSliderData]);

  const onSaveSuggestedTag = useCallback(
    (tag: { value: string; externalId: string }) => {
      if (prospect?.externalTrackingId) {
        dispatch(
          updateProspectTags(getAccessToken, {
            tags: [...(prospect.prospectTags ?? []), tag],
            externalId: prospect.externalTrackingId,
          })
        );
      }
    },
    [dispatch, getAccessToken, prospect]
  );

  const handleDeleteTag = useCallback(
    (tagExternalId: string) => {
      if (prospect?.externalTrackingId) {
        dispatch(deleteProspectTag(getAccessToken, { tagExternalId, externalId: prospect.externalTrackingId }));
      }
    },
    [dispatch, getAccessToken, prospect]
  );

  const suggestedTags: (PortalAPI.TagAPI.TagWithProspectCount & { onClick: () => void })[] = useMemo(() => {
    const tagList =
      currentTags.length > 0
        ? prospect?.prospectTags?.length
          ? differenceBy(currentTags, prospect.prospectTags, (t) => t.externalId)
          : currentTags
        : Utils.SuggestedTags.map((t) => ({
            externalId: uuid.v4(),
            value: t,
            prospectCount: 0,
            hiddenAt: null,
            lastUsedAt: null,
          }));

    return tagList
      .filter((t) => !t.hiddenAt)
      .slice(0, 5)
      .map((tag) => ({
        ...tag,
        onClick: () => onSaveSuggestedTag(tag),
      }));
  }, [currentTags, prospect?.prospectTags, onSaveSuggestedTag]);

  const onOpenContactModal = useCallback(() => {
    if (prospect) {
      dispatch(
        AppActions.setProspectsContactModal({
          prospectId: prospect.externalTrackingId,
        })
      );
    }
  }, [prospect, dispatch]);

  const onOpenProspectInfoModal = useCallback(() => {
    if (prospect) {
      dispatch(
        AppActions.setProspectInfoModal({
          prospectId: prospect.externalTrackingId,
        })
      );
    }
  }, [prospect, dispatch]);

  const onOpenTagsModal = useCallback(() => {
    dispatch(loadAllTags(getAccessTokenSilently));
    if (prospect) {
      dispatch(
        AppActions.setProspectsTagsModal({
          prospectId: prospect.externalTrackingId,
          tags: prospect.prospectTags,
        })
      );
    }
  }, [dispatch, getAccessTokenSilently, prospect]);

  const onOpenForm = useCallback(
    (url: string, args: { subject: string; content: string }) => {
      onOpenUrl(
        buildUrl(url, {
          queryParams: {
            user_id: `${userId}`,
            email: `${email}`,
            ...args,
          },
        })
      );
    },
    [email, onOpenUrl, userId]
  );

  const onClickNotes = useCallback(() => {
    dispatch(
      AppActions.setProspectsNotesModal({
        prospectId: prospect?.externalTrackingId ?? '',
        notes: prospect?.notes ?? '',
      })
    );
  }, [dispatch, prospect]);

  const onClickAppointment = useCallback(() => {
    dispatch(
      AppActions.setNewAppointmentModal({
        prospectId: prospect?.externalTrackingId ?? '',
        appointmentTime: prospect?.prospectAppointment?.appointmentTime,
      })
    );
  }, [dispatch, prospect]);

  const onClickUnassign = useCallback(() => {
    if (prospect) {
      dispatch(
        updateProspectScoutedBy(getAccessToken, {
          assignedToId: null,
          externalId: prospect.externalTrackingId,
        })
      );
    }
  }, [dispatch, getAccessToken, prospect]);

  const contactProps = useMemo(
    () => ({
      name: prospect?.name ?? null,
      phone1: prospect?.phoneNumber ?? null,
      phone2: prospect?.secondaryPhoneNumber ?? null,
      email: prospect?.emailAddress ?? null,
      onClick: onOpenContactModal,
    }),
    [onOpenContactModal, prospect?.emailAddress, prospect?.name, prospect?.phoneNumber, prospect?.secondaryPhoneNumber]
  );

  const homeDataProps: Omit<HomeDataProps, 'onOpenUrl'> = useMemo(
    () => ({
      isLoading: false,
      onOpenForm,
      prospect,
    }),
    [onOpenForm, prospect]
  );

  const tagsSuggestion = useMemo(
    () => ({
      title: 'Add key characteristics',
      show: true,
      action: {
        text: 'Add a Tag',
        onClick: onOpenTagsModal,
      },
      options: suggestedTags,
    }),
    [suggestedTags, onOpenTagsModal]
  );

  const getAppointmentEmptyStateProps = useMemo(
    () =>
      !prospect?.prospectAppointment
        ? { onSetDate: onClickAppointment, onClickTip: () => onOpenUrl(Constants.APPOINTMENT_TIPS_URLS) }
        : null,
    [prospect?.prospectAppointment, onClickAppointment, onOpenUrl]
  );

  const getAppointmentScheduledProps = useMemo(
    () =>
      prospect?.prospectAppointment
        ? {
            appointmentTime: prospect.prospectAppointment?.appointmentTime,
            onEdit: onClickAppointment,
            onAddToCalendar: () => null,
            onTurnOnAlerts: () => null,
          }
        : null,
    [prospect?.prospectAppointment, onClickAppointment]
  );

  if (!prospect) return <div>Loading prospect</div>;

  return (
    <ProspectDetail
      isMobileApp={false}
      prospectId={prospect.externalTrackingId}
      isHomeowner={prospect.occupancyData?.homeowner ?? null}
      isDemographicsLoading={false}
      hasDemographicsDataPackage={hasDemographicData}
      name={prospect.name ?? null}
      addressLine1={prospect.address.address1}
      addressLine2={`${prospect.address.city}, ${prospect.address.state} ${prospect.address.zip}`}
      mailStatus={prospect.mailStatus}
      impressionCount={prospect.deliveryDrips?.progress ?? 0}
      createdDate={prospect.createdAt}
      isDeleting={deleteInProgress}
      assignedToUserId={prospect.assignedTo?.id ?? null}
      user={user}
      handleUpdateMailStatus={handleUpdateMailStatus}
      handleBoostClicked={handleBoostClicked}
      handleLeadStatusUpdate={handleLeadStatusUpdate}
      handleDelete={onDelete}
      onExit={onExit}
      onOpenUrl={onOpenUrl}
      onOpenProspectInfoModal={onOpenProspectInfoModal}
      outerContainerClasses="mt-4"
      leadStatus={prospect.leadStatus}
      leadStatuses={leadStatusSliderData}
      leadStatusUpdatedAt={prospect.leadStatus?.createdAt ?? null}
      leadStatusMailTriggers={leadStatusMailTriggers}
      leadStatusGridLayout
      contact={contactProps}
      notesComponent={
        <ProspectNotes
          onClickNotes={onClickNotes}
          onClickTip={() => onOpenUrl(Constants.NOTES_TIPS_URLS)}
          notes={prospect.notes ?? ''}
          notesUpdatedAt={prospect.notesUpdatedAt ?? null}
          notesUpdatedBy={prospect.notesUpdatedByUserName ?? null}
        />
      }
      appointmentEmptyState={getAppointmentEmptyStateProps}
      appointmentScheduled={getAppointmentScheduledProps}
      onOpenAppointmentModal={onClickAppointment}
      tagsComponent={
        <TagsGroup
          title="Tags"
          tags={prospect?.prospectTags ?? []}
          suggestions={tagsSuggestion}
          onClickAdd={onOpenTagsModal}
          onClickTip={() => onOpenUrl(Constants.TAGS_TIPS_URLS)}
          onDelete={handleDeleteTag}
        />
      }
      homeData={homeDataProps}
      impressionsComponent={null}
      // prospect.mailStatus !== ProspectMailStatus.NONE ? (
      //   <ImpressionsInfoCard
      //     onClickTip={() => onClickTip(Constants.IMPRESSIONS_TIPS_URLS)}
      //     onAddAutomation={console.log}
      //     isEmpty={false}
      //     details={getImpressionsDetails}
      //   />
      // ) : null
      assignedToComponent={
        !!prospect.assignedTo ? (
          <AssignedToCard
            onClickAssign={console.log}
            onSendReminder={console.log}
            onClickUnassign={onClickUnassign}
            assignedUser={prospect.assignedTo}
          />
        ) : null
      }
    />
  );
};

export default Content;
