import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { CampaignAssignmentModal } from './CampaignAssignment';
import { CompanyInviteModal } from './CompanyInvite';
import NotificationModal from './Notification';
import ProspectsDetailsModal from './ProspectsDetails';
import { TeamInviteModal } from './TeamInvite';
import { SubscriptionErrorModal } from './SubscriptionErrorModal';
import { ProspectTagsModal } from './ProspectTagsModal';
import { ProspectNotesModal } from './ProspectNotesModal';
import DuplicateAddressModal from './DuplicateAddress';
import { NewAppointmentModal } from './NewAppointmentModal';
import { EditTagModal } from './EditTagModal';
import { CreateTagModal } from './CreateTagModal';
import { DeleteTagModal } from './DeleteTagModal';
import { HideTagModal } from './HideTagModal';

const Modals = () => {
  const auth0 = useAuth0();

  const authProps = React.useMemo(
    () => ({
      isAuthenticated: auth0.isAuthenticated,
      isLoading: auth0.isLoading,
      getAccessTokenSilently: auth0.getAccessTokenSilently,
    }),
    [auth0.isAuthenticated, auth0.isLoading, auth0.getAccessTokenSilently]
  );
  return (
    <>
      <SubscriptionErrorModal />
      <NotificationModal />
      <DuplicateAddressModal />
      {authProps.isAuthenticated && (
        <>
          <ProspectsDetailsModal {...authProps} />
          <TeamInviteModal {...authProps} />
          <CampaignAssignmentModal {...authProps} />
          <CompanyInviteModal {...authProps} />
          <ProspectTagsModal {...authProps} />
          <ProspectNotesModal {...authProps} />
          <NewAppointmentModal {...authProps} />
          <EditTagModal {...authProps} />
          <CreateTagModal {...authProps} />
          <DeleteTagModal {...authProps} />
          <HideTagModal {...authProps} />
        </>
      )}
    </>
  );
};

export default Modals;
